import { automaticThoughtIdType } from "api/conceptApi";
import { LineWithSolidLabel, TypographyFromDataLine } from "data/CellComponent";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithPercentage,
  InstructionWithTextarea,
} from "../BlockComponent";

import { AutomaticThought, LastCard } from "./common";

export const Content_3_3_A = (automaticThoughtId: automaticThoughtIdType) => [
  Instruction("typography_0_0_0"),
  Instruction("typography_1_0_0"),
  Instruction("typography_2_0_0"),
  Instruction(`typography_3_0_0`),
  InstructionWithContent(
    `typography_4_0_0`,
    [[{ type: "extractFromThoughtRecord", content: { coach: true } }]],
    { hideIfPatient: true }
  ),
  InstructionWithContent(
    `typography_5_0_0`,
    AutomaticThought({ id: automaticThoughtId, isNotConfirmed: true })
  ),
  InstructionWithButtonGroup(`typography_6_0_0`, [
    {
      translationKey: "buttongroup_6_2_0_option0",
      showFromId: "동의",
      hideFromId: "비동의",
    },
    {
      translationKey: "buttongroup_6_2_0_option1",
      showFromId: "비동의",
      hideFromId: "동의",
    },
  ]),

  InstructionWithTextarea("typography_7_0_0", undefined, {
    blockId: "비동의",
    isHidden: true,
  }),
  Instruction("typography_8_0_0", {
    noArrow: true,
    isEnd: true,
    isHidden: true,
  }),
  InstructionWithContent(
    `typography_9_0_0`,
    [
      ...LineWithSolidLabel(
        "상황",
        TypographyFromDataLine({ A: { id: "상황" } }, "common:$A$")
      ),
      ...LineWithSolidLabel(
        "생각",
        TypographyFromDataLine({ A: { id: "생각" } }, "common:$A$")
      ),
      ...LineWithSolidLabel(
        "감정",
        TypographyFromDataLine({ A: { id: "감정" } }, "common:$A$")
      ),
      ...LineWithSolidLabel(
        "신체 반응",
        TypographyFromDataLine({ A: { id: "신체" } }, "common:$A$")
      ),
      ...LineWithSolidLabel(
        "행동",
        TypographyFromDataLine({ A: { id: "행동" } }, "common:$A$")
      ),
    ],
    { blockId: "동의" }
  ),

  InstructionWithDataWithTextarea(
    { A: { id: "생각" } },
    `typographyFromData_10_0_0`
  ),

  InstructionWithPercentage("typography_11_0_0", { id: "영향받는 정도" }),
  InstructionWithTextarea("typography_12_0_0"),

  Instruction("typography_14_0_0"),
  ...LastCard,
];
