import { useCallback, useEffect, useRef, useState } from "react";
import {
  getLastShownIndex,
  hasBlockUserField,
  isBlockUserFieldFilled,
} from "logic/logics";
import { usePrevious } from "hooks/usePrevious";
import * as _ from "lodash";
import Block from "components/task/content/block/Block";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ProgramContentType } from "data/BlockComponent";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { useTheme } from "@mui/material";
import { isBrowser, isMobile } from "react-device-detect";
import { deviceAtom } from "recoil/deviceAtom";

import { layoutAtom } from "recoil/layoutAtom";
import { CircularProgress, Sheet, Stack } from "@mui/joy";
import useSaveContentData from "hooks/useSaveContentData";
import useIsBeta from "hooks/useIsBeta";
import useApiCallBeforeMoveon from "hooks/task/useApiCallBeforeMoveon";
import OptionalModal from "components/common/OptionalModal";
import AppleLoginButton from "components/user/AppleLoginButton";
import GoogleLoginButton from "components/user/GoogleLoginButton";
import InstitutionStartButton from "components/user/InstitutionStartButton";

export default function TaskContent({
  taskKey,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  data,
  setData,
  moveToIndex,
  setMoveToIndex,
  isDone,
  translationVersion,
  isConceptFetching,
}: {
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  data?: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  moveToIndex?: number;
  setMoveToIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  isDone?: boolean;
  translationVersion?: string;
  isConceptFetching: boolean;
}) {
  //변경사항 있을 때 저장
  useSaveContentData({
    taskKey,
    data,
    thoughtRecordKey,
    activityRecordKey,
    meditationRecordKey,
    translationVersion,
  });
  const isBeta = useIsBeta();
  const lastIndex = getLastShownIndex(data);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState<number>(lastIndex);

  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  const device = useRecoilValue(deviceAtom);

  const currentTaskSectionIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const taskSectionState = useRecoilValue(taskSectionAtom);
  const theme = useTheme();
  const { isTaskContainerSmall } = useRecoilValue(layoutAtom);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>();

  const previousData = usePrevious(data);

  const blockRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (previousData) {
        const changedIndex = data.findIndex(
          (element, index) => !_.isEqual(element, previousData[index])
        );
        // sendMessage(
        //   JSON.stringify({
        //     event: "test",
        //     params: {
        //       data: data.find((element) =>
        //         element.lines.flat().find((cell) => cell.type === "audio")
        //       )?.lines[3],
        //     },
        //   })
        // );
        // console.log("[TEST] changedIndex: ", changedIndex);
        if (changedIndex > -1) {
          setCurrentIndex(changedIndex);
        }
        //조건에 따른 블록
        if (
          changedIndex > -1 &&
          hasBlockUserField(data[changedIndex]) &&
          isBlockUserFieldFilled(data[changedIndex]) &&
          !data[changedIndex].hideIfPatient &&
          ((data[changedIndex].nextBlockId &&
            data[changedIndex].nextBlockId !==
              (previousData[changedIndex] as ProgramContentType).nextBlockId) ||
            data[changedIndex].autoOpenNext)
        ) {
          console.log(
            "[TEST]",
            data[changedIndex],
            previousData[changedIndex] as ProgramContentType
          );
          complete(changedIndex);
        }
      }

      if (
        taskKey.includes("0-0-A") &&
        user?.accessToken &&
        data[lastIndex].lines.flat().find((element) => element.type === "login")
      ) {
        complete(lastIndex);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isCoach && data && data.length > 0) {
      //액션 없이 다음 블록 열기
      if (
        lastIndex < data.length - 1 &&
        data[lastIndex].isShown &&
        !data[lastIndex].indentation &&
        (data[lastIndex].noArrow ||
          data[lastIndex].hideIfPatient ||
          data[lastIndex].isAlwaysHidden) &&
        !data[lastIndex].isEnd
      ) {
        complete(lastIndex);
      }
    }
  }, [data]);

  const [doneWithEnter, setDoneWithEnter] = useState<boolean>(false);

  useEffect(() => {
    function getShownNumber(data: ProgramContentType[]) {
      return (data as ProgramContentType[]).filter(
        (element) => element.isShown && !element.isHidden
      ).length;
    }

    if (
      !isCoach &&
      previousData &&
      data &&
      ((lastIndex === data.length - 1 && !isDone) ||
        (lastIndex < data.length - 1 &&
          (!data[lastIndex + 1].isShown || data[lastIndex + 1].isHidden))) &&
      !_.isEqual(previousData, data) &&
      getShownNumber(previousData) > 0 &&
      getShownNumber(previousData) < getShownNumber(data)
    ) {
      setMoveToIndex(lastIndex);
    }
  }, [taskKey, data, previousData, isCoach, lastIndex]);

  const openNextBlock = useCallback(
    (index: number) => {
      setData((data) => {
        const isLastBlock = index === (data || []).length - 1;
        if (data) {
          if (
            // isBlockUserFieldFilled(data[index], data, user) &&
            !isLastBlock &&
            !data[index].isEnd
          ) {
            const data_temp = _.cloneDeep(data);
            const nextBlockIndex = data_temp.findIndex(
              (element, blockIndex) =>
                blockIndex > index &&
                element.blockId &&
                element.blockId === data_temp[index].nextBlockId
            );
            if (nextBlockIndex > -1) {
              data_temp[nextBlockIndex].isShown = true;
              data_temp[nextBlockIndex].isHidden = false;
            } else {
              data_temp[index + 1].isShown = true;
              data_temp[index + 1].isHidden = false;
            }
            return data_temp;
          } else if (isLastBlock || data[index].isEnd) {
            // setDoneWithEnter(true);
          }
          return data;
        }
        return data;
      });
    },
    [data]
  );
  const { action } = useApiCallBeforeMoveon({
    trk: thoughtRecordKey || "",
    setData: setData,
    index: lastIndex,
    queryKey:
      ((data || [])[lastIndex]?.lines[2] || [])[0]?.content.queryKey || [],
    userInput: ((data || [])[lastIndex]?.lines[2] || [])[0]?.content.value,
    openNextBlock: () => openNextBlock(lastIndex),
  });

  const complete = useCallback(
    (index: number) => {
      if (!user?.accessToken && !taskKey.includes("Tool")) {
        setIsLoginModalOpen(true);
      } else {
        (document.activeElement as HTMLElement)?.blur();
        if (
          data &&
          (data[index].isAlwaysHidden ||
            isBlockUserFieldFilled(data[index], data, user))
        ) {
          if (action && thoughtRecordKey) {
            //자사살, 가살에서 호출 안하기 위해 생기지에서만
            action();
          } else {
            openNextBlock(index);
          }
        }
      }
    },
    [data, isCoach, setData, device]
  );

  //엔터로 다음 블록 열기
  useEffect(() => {
    const goNext = (e: KeyboardEvent) => {
      if (
        !isMobile &&
        (e.code === "Enter" ||
          e.code === "NumpadEnter" ||
          e.key === "Enter" ||
          e.key === "NumpadEnter")
      ) {
        if (
          // taskSectionState &&
          // taskSectionState[currentTaskSectionIndex].currentTaskKey ===
          //   taskKey &&
          data &&
          data[lastIndex] &&
          !e.shiftKey
        ) {
          e.preventDefault();
          complete(lastIndex);
        }
      }
    };
    if (!isCoach) {
      window.addEventListener("keypress", goNext);
      return () => window.removeEventListener("keypress", goNext);
    }
  }, [
    data,
    taskKey,
    complete,
    lastIndex,
    taskSectionState,
    currentTaskSectionIndex,
    isCoach,
  ]);

  useEffect(() => {
    if (moveToIndex !== undefined && blockRefs.current[moveToIndex]) {
      blockRefs.current[moveToIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [moveToIndex, thoughtRecordKey]);

  useEffect(() => {
    if (data && !containerHeight) {
      if (scrollRef.current && scrollRef.current?.offsetHeight > 0) {
        setContainerHeight(scrollRef.current?.offsetHeight);
      }
    }
  }, [data]);

  if (!data || data.length === 0) {
    return (
      <Sheet sx={{ backgroundColor: "transparent", height: "100%" }}>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          sx={{ height: "100%" }}
        >
          <CircularProgress color="neutral" />
        </Stack>
      </Sheet>
    );
  }

  return (
    <div
      id="scrollContainer"
      ref={scrollRef}
      onScroll={() => {
        if (moveToIndex !== undefined) {
          setMoveToIndex(undefined);
        }
      }}
      className={`h-full w-full overflow-y-auto py-[20px] ${
        isTaskContainerSmall ? "px-[16px]" : "px-[24px]"
      }`}
      style={{
        paddingBottom: (containerHeight || 72) / 2,
        overflowX: "hidden",
      }}
    >
      <OptionalModal
        open={isLoginModalOpen}
        setOpen={setIsLoginModalOpen}
        title={"로그인 후 이용해주세요"}
        textAlign={"center"}
        content={
          <Stack
            spacing={1}
            sx={{
              width: "100%",
              maxWidth: "500px",
            }}
            alignSelf="center"
          >
            <AppleLoginButton />
            <GoogleLoginButton />
            <InstitutionStartButton />
          </Stack>
        }
      />
      {data.map((blockData, index) => (
        <Block
          ref={(el) => (blockRefs.current[index] = el as HTMLDivElement)}
          key={`${taskKey}_${index}`}
          taskKey={taskKey}
          thoughtRecordKey={thoughtRecordKey}
          activityRecordKey={activityRecordKey}
          meditationRecordKey={meditationRecordKey}
          data={data}
          setData={setData}
          blockDataStr={JSON.stringify(blockData)}
          index={index}
          complete={complete}
          isCurrentIndex={lastIndex === index}
          doneWithEnter={doneWithEnter}
          setDoneWithEnter={setDoneWithEnter}
          isDone={isDone}
          isLastIndex={index === data.length - 1}
          isBeforeNoBorder={index >= 1 && data[index - 1].noBorder}
          isNextOpen={index < data.length - 1 && data[index + 1].isShown}
          translationVersion={translationVersion}
        />
      ))}
    </div>
  );
}
