import { useNavigate, useSearchParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { useEffect } from "react";
import DefaultPage from "./DefaultPage";
import { CircularProgress, Stack } from "@mui/joy";
import { useQueryClient } from "@tanstack/react-query";
import { PATH } from "routes/paths";
import { USER_DETAIL_ENDPOINT, USER_DOMAIN } from "api/userApi";
import { sendMessage } from "utils/webview";

export default function SteppayPaymentSuccess() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const orderId = searchParams.get("order_id");
  const orderCode = searchParams.get("order_code");
  const status = searchParams.get("status");

  useEffect(() => {
    if (status === "success") {
      if (orderId && orderCode) {
        paymentSuccess();
      }
    } else {
      navigate(PATH.steppayPaymentFail);
    }
  }, [orderId, orderCode, status]);

  const [paymentSuccessData, paymentSuccess] = useFetch({
    url: `/payment/steppay/confirm`,
    requestBody: JSON.stringify({
      orderId: orderId,
      orderCode: orderCode,
    }),
  });
  useEffect(() => {
    if (paymentSuccessData) {
      navigate("/");
      queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
      sendMessage(
        JSON.stringify({
          event: "close_steppay",
        })
      );
    }
  }, [paymentSuccessData]);

  // const { mutate: confirm } = useMutation(
  //   () =>
  //     paymentApi.confirmSteppay({
  //       orderId: orderId,
  //       orderCode: orderCode,
  //     } as SteppayConfirmType),
  //   {
  //     onSuccess: () => {
  //       // navigate("/");
  //     },
  //   }
  // );

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}
