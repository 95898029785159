import { useEffect, useRef } from "react";
import { Box, Sheet } from "@mui/joy";
import * as _ from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { usePrevious } from "@uidotdev/usehooks";
import Activity from "./Activity";
import ThoughtRecord from "./thoughtRecord/ThoughtRecord";
import { Toaster } from "sonner";
import "./toast.css";
import Payment from "./Payment";
import Chat from "./Chat";
import MyThoughtMap from "./MyThoughtMap";
import MyProgress from "./MyProgress";
import ActivityRecord from "./activityRecord/ActivityRecord";
import MeditationRecord from "./MeditationRecord/MeditationRecord";
import MyValueCompass from "./MyValueCompass";
import { axiosInstance } from "api";
import { userAtom } from "recoil/userAtom";
import { patientAtom } from "recoil/patientAtom";
import { taskListAtom } from "recoil/taskListAtom";
import useIsInappWebview from "hooks/useIsInappWebview";
import { isDev } from "config";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";

export interface VisibleIndexType {
  min?: number;
  max?: number;
}

export default function Task({ taskKey }: { taskKey: string }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [layoutState, setLayoutState] = useRecoilState(layoutAtom);
  const previous_layoutState = usePrevious(layoutState);
  const taskSectionState = useRecoilValue(taskSectionAtom);
  const previous_taskSectionState = usePrevious(taskSectionState);

  const patientState = useRecoilValue(patientAtom);

  const handleResize = () => {
    const containerWidth = containerRef.current?.offsetWidth || 0;

    if (containerWidth > 0) {
      if (
        (layoutState.isTaskContainerSmall && containerWidth >= 800) ||
        (!layoutState.isTaskContainerSmall && containerWidth < 800)
      ) {
        setLayoutState((state) => ({
          ...state,
          isTaskContainerSmall: containerWidth < 800,
        }));
      }
    }
  };

  useEffect(() => {
    if (layoutState && previous_layoutState) {
      const layoutState_copy = _.cloneDeep(layoutState);
      const previous_layoutState_copy = _.cloneDeep(previous_layoutState);
      delete layoutState_copy.isTaskContainerSmall;
      delete previous_layoutState_copy.isTaskContainerSmall;
      if (!_.isEqual(layoutState_copy, previous_layoutState_copy)) {
        handleResize();
      }
    } else if (!previous_layoutState) {
      handleResize();
    }
  }, [layoutState]);

  useEffect(() => {
    if (
      taskSectionState &&
      (!previous_taskSectionState ||
        taskSectionState.length !== previous_taskSectionState.length)
    ) {
      handleResize();
    }
  }, [taskSectionState]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const user = useRecoilValue(userAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const isInappWebview = useIsInappWebview();

  const { isSubscribing, isNotStarted } = useSubscriptionStatus();

  const isPatient = !!user?.accessToken && user?.role === "patient";
  const showPaymentModule = !user?.accessToken
    ? !isInappWebview
    : isPatient && !isSubscribing;

  return (
    <Sheet
      className={"w-full h-full grow flex flex-col "}
      sx={{ backgroundColor: "background.level1" }}
      ref={containerRef}
    >
      {/* {isDev &&
        `at:${user?.accessToken}\n pid:${patientState?.patientId}\n taskList:${taskListState?.length} `} */}
      <Toaster position="top-center" richColors closeButton duration={2000} />
      {((!!axiosInstance.defaults.headers["X-Auth-Token"] &&
        !!user?.accessToken &&
        !!patientState?.patientId) ||
        !isInappWebview) &&
        taskListState &&
        taskListState.length > 0 &&
        (taskKey.includes("payment") ? (
          <Payment />
        ) : taskKey.includes("chat") ? (
          <Chat />
        ) : taskKey.includes("thoughtmap") ? (
          <MyThoughtMap showPaymentModule={showPaymentModule} />
        ) : taskKey.includes("valueCompass") ? (
          <MyValueCompass showPaymentModule={showPaymentModule} />
        ) : taskKey.includes("progress") ? (
          <MyProgress showPaymentModule={showPaymentModule} />
        ) : taskKey.includes("2-2-T") ? (
          <ThoughtRecord taskKey={taskKey} />
        ) : taskKey.includes("5-7-T") ? (
          <MeditationRecord taskKey={taskKey} />
        ) : taskKey.includes("101-1-3-T") ? (
          <ActivityRecord taskKey={taskKey} />
        ) : (
          <Activity taskKey={taskKey} />
        ))}
    </Sheet>
  );
}
