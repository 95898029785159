import { useRecoilValue } from "recoil";
import { Box, Button, Grid, Stack } from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import { patientAtom } from "recoil/patientAtom";
import {
  dashboardApi,
  DashboardType,
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "./ContentTemplate";
import { getExtraAutomaticThoughtKey } from "logic/logics";
import ThoughtMap from "components/thoughtmap/ThoughtMap";
import useOpenTask from "hooks/useOpenTask";

function Section({ content }: { content: React.ReactNode }) {
  return <Box sx={{ height: "100%" }}>{content}</Box>;
}

export default function MyThoughtMapContent() {
  const patientState = useRecoilValue(patientAtom);

  const loadDashboardQueryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState?.patientId]
  );

  const blankDashboardData = {
    concept: {},
    history: {},
    selfCheckResult: {},
  };
  const [dashboardData, setDashboardData] = useState<DashboardType>();
  useQuery(
    [DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT, patientState?.patientId],
    loadDashboardQueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        console.log("[TEST] dashboard data:", data.result);
        setDashboardData(data.result);
      },
    }
  );

  useEffect(() => {
    console.log("[TEST] patientid:", patientState?.patientId);
    if (!patientState?.patientId) {
      setDashboardData(blankDashboardData);
    }
  }, [patientState]);

  return (
    <ContentTemplate>
      <Stack spacing={2}>
        {dashboardData && (
          <Stack spacing={2}>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              sx={{ flexGrow: 1, width: "100%" }}
            >
              <Grid xs={12} sx={{ p: 0 }}>
                <Section
                  content={
                    <ThoughtMap
                      data={{
                        ...dashboardData?.concept,
                        [getExtraAutomaticThoughtKey(dashboardData?.concept)]:
                          undefined,
                        // automaticThought1: undefined,
                      }}
                      includeAutomaticThoughtDetail={true}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Stack>
    </ContentTemplate>
  );
}
