import { automaticThoughtIdType } from "api/conceptApi";
import { ButtonGroup, DividerLine, Thoughtmap } from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithTextarea,
} from "../BlockComponent";

import { LastCard, Value } from "./common";

export const Content_6_11_A = (
  automaticThoughtForValueIdList: automaticThoughtIdType[]
) => [
  Instruction(`typography_0`),
  Instruction("typography_1"),
  Instruction(`typography_2`),
  Instruction(`typography_3`),
  Instruction(`typography_4`),
  Instruction(`typography_5`),
  Instruction(`typography_6`),
  Instruction(`typography_7`),

  Header1(`typography_8`),
  Instruction(`typography_9`),
  Instruction(`typography_10`),
  Instruction(`typography_11`),
  Instruction(`typography_12`),
  Instruction(`typography_13`),
  Instruction(`typography_14`),
  Instruction(`typography_15`),
  Instruction(`typography_16`),
  Instruction(`typography_17`),

  Header1(`typography_18`),
  Instruction(`typography_19`),
  Instruction(`typography_20`),
  Instruction(`typography_21`),
  Instruction(`typography_22`),
  Instruction(`typography_23`),
  Instruction(`typography_24`),
  Instruction(`typography_25`),
  Instruction(`typography_26`),

  Header1(`typography_27`),
  Instruction(`typography_28`),
  Instruction(`typography_29`),
  Instruction(`typography_30`),
  Instruction(`typography_31`),
  Instruction(`typography_32`),
  Instruction(`typography_33`),
  Instruction(`typography_34`),
  Instruction(`typography_35`),
  Instruction(`typography_36`),
  Instruction(`typography_37`),

  Header1(`typography_38`),
  Instruction(`typography_39`),
  Instruction(`typography_40`),
  Instruction(`typography_41`),
  Instruction(`typography_42`),
  Instruction(`typography_43`),
  Instruction(`typography_44`),
  InstructionWithContent(`typography_45`, [Thoughtmap()]),
  Instruction(`typography_46`),
  ...automaticThoughtForValueIdList
    .map((id, index) => [
      InstructionWithContent(
        `typography_47`,
        [
          ...Value({ id: id }),
          [
            ButtonGroup([
              {
                text: "네, 제가 선택한 삶의 방향이에요",
                showFromId:
                  index === automaticThoughtForValueIdList.length - 1
                    ? `wrapup`
                    : `value${automaticThoughtForValueIdList[index + 1]}`,
                hideFromId: `비동의_${id}`,
              },
              {
                text: "아니요, 어쩌면 대처 전략이었을 수도 있을 것 같아요",
                showFromId: `비동의_${id}`,
                hideFromId:
                  index === automaticThoughtForValueIdList.length - 1
                    ? `wrapup`
                    : `value${automaticThoughtForValueIdList[index + 1]}`,
              },
            ]),
          ],
        ],
        { blockId: `value${id}` }
      ),
      InstructionWithTextarea("typography_47_no_1", undefined, {
        blockId: `비동의_${id}`,
        isHidden: true,
      }),
    ])
    .flat(),

  Instruction(`typography_48`, { blockId: "wrapup" }),
  Instruction(`typography_49`),
  Instruction(`typography_50`),

  ...LastCard,
];
