import { automaticThoughtIdType } from "api/conceptApi";
import {
  DividerLine,
  SolidLabelLine,
  TextareaLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
} from "../BlockComponent";
import { LastCard, Value } from "./common";

export const Content_7_6_A = (automaticThoughtId: automaticThoughtIdType) => [
  InstructionWithContent("typography_0_0_0", Value({ id: automaticThoughtId })),
  Instruction("typography_1_0_0"),
  Header1("typography_2_0_0"),
  Instruction(`typography_3_0_0`),
  InstructionWithDataWithTextarea({ A: { id: "가치" } }, "typography_4_0_0"),
  InstructionWithDataWithTextarea({ A: { id: "가치" } }, "typography_5_0_0"),
  InstructionWithDataWithTextarea({ A: { id: "가치" } }, "typography_6_0_0"),
  Instruction("typography_7_0_0"),
  Header1("typography_8_0_0"),
  Instruction("typography_9_0_0"),
  Instruction("typography_10_0_0"),
  Instruction("typography_11_0_0"),
  Instruction("typography_12_0_0"),
  InstructionWithContent("typography_13_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`단계`, { prefixLabelIndex: each }),
      TypographyLine(`typography_13_${each}_0`),
    ]),
  ]),
  Instruction("typography_14_0_0"),
  Instruction("typography_15_0_0"),

  InstructionWithDataWithContent({ A: { id: "가치" } }, "typography_16_0_0", [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`단계`, { prefixLabelIndex: each }),
      TextareaLine({
        id: `step_${each}`,
        placeholder: `일주일 내에 해낼 수 있는 일회성 행동을 적어보세요`,
      }),
    ]),
  ]),
  Instruction("typography_17_0_0"),
  Instruction("typography_18_0_0"),
  ...LastCard,
];
