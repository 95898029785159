import {
  InstructionWithButtonGroup,
  InstructionWithContent,
} from "../BlockComponent";
import { Audio, Image } from "../CellComponent";

export const MeditationToolTemplate = [
  InstructionWithButtonGroup("typography_0_0_0", [
    {
      text: "하루 시작",
      showFromId: "하루 시작",
      hideFromId: "하루 마무리",
    },
    {
      text: "생각의 강",
      showFromId: "생각의 강",
      hideFromId: "하루 시작",
    },
    {
      text: "생각의 공장",
      showFromId: "생각의 공장",
      hideFromId: "하루 시작",
    },
    {
      text: "생각의 하늘",
      showFromId: "생각의 하늘",
      hideFromId: "하루 시작",
    },
    {
      text: "하루 마무리",
      showFromId: "하루 마무리",
      hideFromId: "하루 시작",
    },
  ]),
  ...[
    { blockId: "하루 시작", url: "start_of_day", imgSrc: "0" },
    { blockId: "하루 마무리", url: "end_of_day", imgSrc: "4" },
  ]
    .map(({ blockId, url, imgSrc }, index) => [
      ...[
        InstructionWithButtonGroup(
          `typography_1_0_0_${index === 1 ? 4 : index}`,
          [
            {
              text: "10분",
              showFromId: `${blockId}_오디오_10분`,
              hideFromId: `${blockId}_오디오_15분`,
            },
            {
              text: "15분",
              showFromId: `${blockId}_오디오_15분`,
              hideFromId: `${blockId}_오디오_10분`,
            },
          ],
          undefined,
          { blockId: blockId }
        ),
        InstructionWithContent(
          "typography_2_0_0",
          [Image(imgSrc), Audio(`${url}_${"10min"}`)],
          {
            blockId: `${blockId}_오디오_10분`,
            isEnd: true,
          }
        ),
        InstructionWithContent(
          "typography_2_0_0",
          [Image(imgSrc), Audio(`${url}_${"15min"}`)],
          {
            blockId: `${blockId}_오디오_15분`,
            isEnd: true,
          }
        ),
      ],
    ])
    .flat(),
  // ...[
  //   { name: "하루 시작", url: "start_of_day", imgSrc: "0" },
  //   { name: "하루 마무리", url: "end_of_day", imgSrc: "4" },
  // ]
  //   .map(({ name, url, imgSrc }, index) => [
  //     InstructionWithContent(
  //       "typography_2_0_0",
  //       [Image(imgSrc), Audio(`${url}_10min`)],
  //       {
  //         blockId: `${name}_오디오_10분`,
  //         isEnd: true,
  //       }
  //     ),
  //     InstructionWithContent(
  //       "typography_2_0_0",
  //       [Image(imgSrc), Audio(`${url}_15min`)],
  //       {
  //         blockId: `${name}_오디오_15분`,
  //         isEnd: true,
  //       }
  //     ),
  //   ])
  //   .flat(),
  ...[
    { blockId: "생각의 강", url: "stream_of_thoughts", imgSrc: "1" },
    { blockId: "생각의 공장", url: "factory_of_thoughts", imgSrc: "2" },
    { blockId: "생각의 하늘", url: "sky_of_thoughts", imgSrc: "3" },
  ]
    .map(({ blockId, url, imgSrc }, index) => [
      ...[
        InstructionWithButtonGroup(
          `typography_1_0_0`,
          [
            {
              text: "호흡에 초점 두기",
              showFromId: `${blockId}_호흡`,
              hideFromId: `${blockId}_그냥`,
            },
            {
              text: "그냥 해보기",
              showFromId: `${blockId}_그냥`,
              hideFromId: `${blockId}_호흡`,
            },
          ],
          undefined,
          { blockId: blockId }
        ),
        ...[
          { type: `호흡`, url: `${url}_breath` },
          { type: `그냥`, url: `${url}` },
        ]
          .map(({ type, url }) => [
            InstructionWithButtonGroup(
              `typography_1_0_0_${index + 1}`,
              [
                {
                  text: "10분",
                  showFromId: `${blockId}_${type}_오디오_10분`,
                  hideFromId: `${blockId}_${type}_오디오_15분`,
                },
                {
                  text: "15분",
                  showFromId: `${blockId}_${type}_오디오_15분`,
                  hideFromId: `${blockId}_${type}_오디오_10분`,
                },
              ],
              undefined,
              {
                blockId: `${blockId}_${type}`,
              }
            ),
            InstructionWithContent(
              "typography_2_0_0",
              [Image(imgSrc), Audio(`${url}_${"10min"}`)],
              {
                blockId: `${blockId}_${type}_오디오_10분`,
                isEnd: true,
              }
            ),
            InstructionWithContent(
              "typography_2_0_0",
              [Image(imgSrc), Audio(`${url}_${"15min"}`)],
              {
                blockId: `${blockId}_${type}_오디오_15분`,
                isEnd: true,
              }
            ),
          ])
          .flat(),
      ],
    ])
    .flat(),
  // ...[
  //   { name: "하루 시작", url: "start_of_day", imgSrc: "0" },
  //   { name: "하루 마무리", url: "end_of_day", imgSrc: "4" },
  // ]
  //   .map(({ name, url, imgSrc }, index) => [
  //     InstructionWithContent(
  //       "typography_2_0_0",
  //       [Image(imgSrc), Audio(`${url}_10min`)],
  //       {
  //         blockId: `${name}_오디오_10분`,
  //         isEnd: true,
  //       }
  //     ),
  //     InstructionWithContent(
  //       "typography_2_0_0",
  //       [Image(imgSrc), Audio(`${url}_15min`)],
  //       {
  //         blockId: `${name}_오디오_15분`,
  //         isEnd: true,
  //       }
  //     ),
  //   ])
  //   .flat(),
];
