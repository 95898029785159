import { automaticThoughtIdType } from "api/conceptApi";
import { Percentage } from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
} from "../BlockComponent";
import {
  AutomaticThought,
  LastCard,
  NotificationOnceAndInstructionBlocks,
} from "./common";

export const Content_5_6_A = (
  automaticThoughtId: automaticThoughtIdType,
  isPushNotificationGranted: boolean
) => [
  Instruction(`typography_0_0_0`),
  InstructionWithContent(
    `typography_1_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  Instruction(`typography_2_0_0`),
  Instruction(`typography_3_0_0`),
  Instruction(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  Instruction(`typography_6_0_0`),

  ...NotificationOnceAndInstructionBlocks(
    isPushNotificationGranted,
    "0",
    "observing_mind_10min",
    true,
    "meditation_available_10min"
  ),
  InstructionWithDataWithContent(
    { A: { taskId: `4-2-A_${automaticThoughtId}`, id: "영향받는 정도" } },
    "typography_11_0_0",
    [[Percentage({ id: "영향받는 정도" })]]
  ),
  Instruction(`typography_12_0_0`),
  ...LastCard,
];
