import { useRecoilValue } from "recoil";
import { Stack, Typography } from "@mui/joy";
import PaymentButton from "components/payment/PaymentButton";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import ContentTemplate from "./ContentTemplate";
import { isDev } from "config";
import Keypoints from "components/payment/Keypoints";
import PromotionBanner from "components/payment/PromotionBanner";
import QnA from "components/payment/QnA";
import { FreeTrialInstruction } from "components/payment/Price";
import Link from "components/common/Link";
import useGetUrls from "hooks/useGetUrls";
import FloatingArea from "pages/tool/thought-record/FloatingArea";
import Reviews from "components/payment/Reviews";
import FreeTrialTimeline from "components/payment/FreeTrialTimeline";

export default function PaymentContent() {
  const { hasFreeTrialUsed } = useSubscriptionStatus();
  const { privacyPolicyUrl, servicePolicyUrl } = useGetUrls();
  const title = !hasFreeTrialUsed
    ? "코치 선생님과\n프로그램을 시작하세요"
    : "코치 선생님과\n프로그램을 이어가세요";
  const subtitle = !hasFreeTrialUsed
    ? "체험이 종료되기 전 알려드릴 거예요."
    : undefined;

  return (
    <ContentTemplate>
      <Stack spacing={3} sx={{ pb: "150px" }}>
        <Stack spacing={"52px"}>
          <Stack spacing={"30px"}>
            <TitleWithSubtitle
              title={title}
              // titleHighlightedText={"프로그램"}
              subtitle={subtitle}
              titleLevel="h2"
              sx={{ textAlign: "center" }}
            />
            {/* <PromotionBanner /> */}
            {!hasFreeTrialUsed && <FreeTrialTimeline />}
            <Keypoints />
          </Stack>
          <Stack spacing={"30px"}>
            <Typography
              level="h2"
              fontWeight={700}
              sx={{ textAlign: "center" }}
            >
              {"생각과 거리두고\n가치로 나아가세요"}
            </Typography>
            <Reviews />
          </Stack>
          <Stack spacing={"30px"}>
            <Typography
              level="h2"
              fontWeight={700}
              sx={{ textAlign: "center" }}
            >
              {"자주 묻는 질문"}
            </Typography>
            <QnA />
          </Stack>
        </Stack>
        {/* <PromotionBanner /> */}
        <FloatingArea>
          {!hasFreeTrialUsed && <FreeTrialInstruction />}
          <PaymentButton />
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ pt: 1, wordBreak: "keep-all" }}
          >
            <Link text={"개인정보 처리방침"} url={privacyPolicyUrl} />
            <Link text={"서비스 이용약관"} url={servicePolicyUrl} />
          </Stack>
        </FloatingArea>
      </Stack>
    </ContentTemplate>
  );
}
