import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import DoneButton from "components/task/header/DoneButton";
import {
  getTaskIdFromTaskKey,
  isAllCoachFieldFilled,
  isAllUserFieldFilled,
} from "logic/logics";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { Box, useTheme } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import TaskProgressBar, { rainbowGradient } from "./TaskProgressBar";
import UndoneButton from "./UndoneButton";
import TaskCloseButton from "./TaskCloseButton";
import useGetTaskTitleFromTaskKey from "hooks/useGetTaskTitleFromTaskKey";
import InitializeButton from "./InitializeButton";
import { ProgramType } from "data/programData";
import { isDev } from "config";
import { taskListAtom } from "recoil/taskListAtom";
import SidebarTogglButton from "components/header/SideBarToggleButton";
import HeaderBackButton from "./HeaderBackButton";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { layoutAtom } from "recoil/layoutAtom";
import OpenSidebarButton from "./OpenSidebarButton";
import OpenConceptNoteButton from "./OpenConceptNoteButton";
import useIsInappWebview from "hooks/useIsInappWebview";
import BackButton from "./BackButton";
import useIsLatest from "hooks/useIsLatest";

export default function TaskHeader({
  taskKey,
  title,
  contentData,
  setContentData,
  setData,
  setMoveToIndex,
  thoughtRecordKey,
  activityRecordKey,
  meditationRecordKey,
  isDone,
  canMoveOn,
  isInitialized,
  extraContent,
  lastDoneAt,
}: {
  taskKey: string;
  title?: string;
  contentData?: ProgramContentType[];
  setContentData?: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  setData?: React.Dispatch<React.SetStateAction<ProgramType | undefined>>;
  setMoveToIndex?: React.Dispatch<React.SetStateAction<number | undefined>>;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  isDone?: boolean;
  canMoveOn?: boolean;
  isInitialized?: boolean;
  extraContent?: React.ReactNode;
  lastDoneAt?: string;
}) {
  const hasData = !!contentData;
  const isTool =
    taskKey.includes("2-2-T") &&
    (thoughtRecordKey || activityRecordKey || meditationRecordKey);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const isAdmin = user?.role === "admin";
  const isManual = isCoach && user?.isCoachAiSupportDisabled;

  const taskTitle = useGetTaskTitleFromTaskKey(taskKey);

  const taskListState = useRecoilValue(taskListAtom);
  const isOpen = taskListState?.find(
    (element) => element.taskKey === taskKey
  )?.isOpen;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isInappWebview = useIsInappWebview();

  const [{ isLeftSideBarOpen, isRightSideBarOpen }, setLayoutState] =
    useRecoilState(layoutAtom);

  const isLatest = useIsLatest();

  return (
    <>
      <Stack
      // sx={{ backgroundColor: "white" }}
      >
        <Stack
          direction={"column"}
          sx={{
            px: 0,
            height: "var(--StickyHeader-height)",

            ...(!hasData && {
              borderBottomWidth: "1px",
              borderColor: "divider",
            }),
          }}
        >
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={"8px"}
            sx={{
              flex: 1,
              height: "100%",
              px: "20px",
            }}
          >
            {/* {isInappWebview ? (
              <HeaderBackButton />
            ) : !isLeftSideBarOpen ? (
              <OpenSidebarButton />
            ) : (
              <></>
            )} */}
            {isLatest ? <BackButton /> : <HeaderBackButton />}
            <Stack
              direction="row"
              alignItems={"center"}
              spacing={1}
              flexGrow={1}
            >
              {isCoach &&
                contentData &&
                !isAllCoachFieldFilled(contentData) && (
                  <Box
                    sx={{
                      color: "danger.500",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span className="material-symbols-outlined ">error</span>
                  </Box>
                )}
              <Stack direction="row" alignItems={"center"} spacing={2}>
                <Typography
                  level="title-md"
                  flexGrow={1}
                  sx={{
                    fontWeight: 700,
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {title || taskTitle}
                </Typography>
                {isCoach &&
                  getTaskIdFromTaskKey(taskKey) === "1-1-A" &&
                  canMoveOn && (
                    <Typography
                      level="body-xs"
                      sx={{
                        whiteSpace: "nowrap",
                        background: rainbowGradient,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        color: "transparent",
                        display: "inline",
                      }}
                    >
                      {"넘어가도 좋아요"}
                    </Typography>
                  )}
              </Stack>
            </Stack>

            {hasData && (
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={1.5}
                sx={{ py: 0 }}
              >
                {!isCoach ? (
                  <>
                    {thoughtRecordKey &&
                      lastDoneAt &&
                      !isDone &&
                      isAllUserFieldFilled(contentData, false) && (
                        <Typography
                          level="body-xs"
                          color="danger"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          마치기를 눌러주세요
                        </Typography>
                      )}
                    <DoneButton
                      taskKey={taskKey}
                      data={contentData}
                      thoughtRecordKey={thoughtRecordKey}
                      activityRecordKey={activityRecordKey}
                      meditationRecordKey={meditationRecordKey}
                      isDone={isDone}
                      size="sm"
                    />
                  </>
                ) : (
                  <>
                    {!isTool && setData && (
                      <InitializeButton
                        taskKey={taskKey}
                        setData={setData}
                        isInitialized={isInitialized}
                      />
                    )}

                    {isManual && (
                      <TaskCloseButton
                        taskKey={taskKey}
                        data={contentData}
                        thoughtRecordKey={thoughtRecordKey}
                        activityRecordKey={activityRecordKey}
                        meditationRecordKey={meditationRecordKey}
                        isDone={isDone}
                      />
                    )}
                    {((!isOpen && !isTool) || isManual || isAdmin || isDev) &&
                      setContentData && (
                        <UndoneButton
                          taskKey={taskKey}
                          data={contentData}
                          setData={setContentData}
                          thoughtRecordKey={thoughtRecordKey}
                          activityRecordKey={activityRecordKey}
                          meditationRecordKey={meditationRecordKey}
                          isDone={isDone}
                        />
                      )}
                  </>
                )}
              </Stack>
            )}
            {!isRightSideBarOpen && isCoach && <OpenConceptNoteButton />}
          </Stack>
        </Stack>
        {extraContent}
      </Stack>
      {setMoveToIndex && (
        <TaskProgressBar
          taskKey={taskKey}
          data={contentData}
          setMoveToIndex={setMoveToIndex}
        />
      )}
    </>
  );
}
