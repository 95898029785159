import { useCallback, useEffect } from "react";
import { axiosInstance, setAuthHeaders } from "api";
import { useRecoilState, useResetRecoilState } from "recoil";
import { userAtom } from "recoil/userAtom";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { patientAtom } from "recoil/patientAtom";
import { Sheet, Stack } from "@mui/joy";
import { getRedirectPath } from "logic/logics";
import InsideC from "components/ai/InsideC";
import Sidebar from "components/sidebar/SideBar2";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import LogoEmptyPage from "static/image/common/LogoEmptyPage";
import { userApi, USER_DETAIL_ENDPOINT, USER_DOMAIN } from "api/userApi";
import { useQuery } from "@tanstack/react-query";
import duration from "dayjs/plugin/duration";
import useOpenTask from "hooks/useOpenTask";
import { sendMessage } from "utils/webview";
import useRemoveTab from "hooks/useRemoveTab";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import useIsInappWebview from "hooks/useIsInappWebview";
import Task from "components/task/Task";
import useSetConceptData from "hooks/useSetConceptData";
import { isMobile } from "react-device-detect";
import { deepLinkData } from "data/deepLinkData";

export default function Home() {
  const navigate = useNavigate();
  const { taskKey } = useParams();

  // const decodedTaskKey = decodeURIComponent(taskKey || "");
  const goOnboarding = () => {
    navigate("/onboarding/1", { replace: true });
  };
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const isPayment = searchParams.get("tab") === "payment";
  const pid = searchParams.get("pid");
  const openPayment = useOpenTask("payment");
  const openThoughtmap = useOpenTask("thoughtmap");
  const isInappWebview = useIsInappWebview();

  dayjs.extend(duration);

  const [user, setUser] = useRecoilState(userAtom);
  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const isCoach = user?.accessToken && user?.role !== "patient";
  const resetPid = useResetRecoilState(patientAtom);

  useEffect(() => {
    if (pid && isCoach) {
      setPatientState({
        patientId: `${pid}`,
      });
    }
  }, [pid]);

  useEffect(() => {
    if (isCoach) {
      resetPid();
    }
  }, []);

  const queryFn = useCallback(() => userApi.getDetail(), []);

  const { removeTab: removePayment } = useRemoveTab({ taskKey: "payment" });
  const refetchTaskList = useRefetchTaskList();

  useQuery([USER_DOMAIN, USER_DETAIL_ENDPOINT], queryFn, {
    enabled:
      !!axiosInstance.defaults.headers["X-Auth-Token"] && !!user?.accessToken, // webview에서 토큰 받아오기 전에 못불러오게

    onSuccess: (data) => {
      if (data && data.result) {
        console.log("user", data.result);
        if (data.result.role === "patient") {
          setPatientState({
            patientId: `${data.result.userId}`,
            nickname: data.result.nickname,
          });
        }
        //결제 탭 닫기
        if (data.result.subscription?.isSubscribing) {
          if (taskKey === "payment" && !user?.subscription?.isSubscribing) {
            removePayment();
          }

          //자물쇠 풀기
          refetchTaskList();
        }
        if (!isCoach && !data.result.isAcquisitionChannelSubmitted) {
          goOnboarding();
        }

        setUser((user) => ({
          ...user,
          ...data.result,
        }));
      }
    },
  });

  useEffect(() => {
    if (user && user.accessToken) {
      setAuthHeaders(user.accessToken);
      console.log(
        "setAuthHeaders",
        axiosInstance.defaults.headers["X-Auth-Token"]
      );
      sendMessage(
        JSON.stringify({
          event: "value",
          params: {
            accessToken: user.accessToken,
          },
        })
      );
      if (isPayment) {
        openPayment();
      }
    } else {
      if (isInappWebview) {
        window.location.href = getRedirectPath(user);
      } else {
        const timeoutId = setTimeout(() => {
          // 일정 시간 후에도 undefined라면 브라우저로 간주
          if (isInappWebview === undefined && isMobile) {
            window.location.href = deepLinkData.install.url;
          }
        }, 1000); // 1초 대기

        return () => clearTimeout(timeoutId);
      }
    }
  }, [user?.accessToken, isInappWebview]);

  useEffect(() => {
    if (!isInappWebview) {
      navigate(`/`, { replace: true });

      if (patientState?.patientId) {
        openThoughtmap();
      }
    }
  }, [patientState?.patientId]);

  return (user?.accessToken &&
    !!axiosInstance.defaults.headers["X-Auth-Token"]) ||
    (!isInappWebview && !isMobile) ? (
    <Sheet
      sx={{
        backgroundColor: "background.level2",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        whiteSpace: "pre-line",
      }}
      className="w-full h-full"
    >
      <Sidebar isHome={window.location.pathname === "/"} />

      <Stack
        sx={{
          flex: 1,
          backgroundColor: "background.level1",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minWidth: 0,
        }}
      >
        {taskKey && <Task key={`task_${taskKey}`} taskKey={taskKey} />}
      </Stack>

      {isCoach && patientState?.patientId && (
        <InsideC patientId={patientState.patientId} />
      )}
    </Sheet>
  ) : (
    <LogoEmptyPage />
  );
}
