import { Box, Stack } from "@mui/joy";
import React from "react";
import Link from "components/common/Link";
import { useTranslation } from "react-i18next";
import useGetUrls from "hooks/useGetUrls";
import logo from "static/image/common/distancing_logo.png";
import FullPageContainer from "./FullPageContainer";

export default function DefaultPage({ content }: { content: React.ReactNode }) {
  return (
    <FullPageContainer hasFooter>
      <Box>
        <img src={logo} alt={"empty"} style={{ width: "132px" }} />
      </Box>
      <Stack
        flexGrow={1}
        justifyContent="center"
        sx={{ width: "100%", px: "20px" }}
      >
        {content}
      </Stack>
    </FullPageContainer>
  );
}
