import PatientMenuBar from "components/task/content/block/PatientMenuBar";
import * as _ from "lodash";
import { Box, Sheet, Typography } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import {
  getAiCommentKeyInBlock,
  getTaskIdFromTaskKey,
  isBlockUserFieldFilled,
  isLineHidden,
} from "logic/logics";
import CoachMenuBar from "./CoachMenuBar";
import { layoutAtom } from "recoil/layoutAtom";
import { ProgramContentType } from "data/BlockComponent";
import React, { memo } from "react";
import DoneButton from "components/task/header/DoneButton";
import Line from "./Line";
import { deviceAtom } from "recoil/deviceAtom";
import { CommentArrowSvg } from "static/image/common/SvgAssets";

interface PropsType {
  taskKey: string;
  thoughtRecordKey?: string;
  activityRecordKey?: string;
  meditationRecordKey?: string;
  data: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  blockDataStr: string;
  index: number;
  complete: (index: number) => void;
  isCurrentIndex: boolean;
  doneWithEnter?: boolean;
  setDoneWithEnter?: React.Dispatch<React.SetStateAction<boolean>>;
  isDone?: boolean;
  isLastIndex: boolean;
  isBeforeNoBorder?: boolean;
  isNextOpen?: boolean;
  translationVersion?: string;
}

const Block = React.forwardRef<HTMLDivElement, PropsType>(
  (
    {
      taskKey,
      thoughtRecordKey,
      activityRecordKey,
      meditationRecordKey,
      data,
      setData,
      blockDataStr,
      index,
      complete,
      isCurrentIndex,
      doneWithEnter,
      setDoneWithEnter,
      isDone,
      isLastIndex,
      isBeforeNoBorder,
      isNextOpen,
      translationVersion,
    }: PropsType,
    ref
  ) => {
    const blockData: ProgramContentType = {
      ...JSON.parse(blockDataStr),
    };

    const user = useRecoilValue(userAtom);
    const device = useRecoilValue(deviceAtom);
    const isCoach = (user?.accessToken && user?.role !== "patient") || false;

    const isThoughtRecord = taskKey.includes("2-2-T");

    const { taskWidth } = useRecoilValue(layoutAtom);

    const highlight = blockData && blockData.isHighlight;

    const isHidden =
      blockData &&
      (blockData.isHidden ||
        blockData.isAlwaysHidden ||
        (isCoach && !blockData.isShown && blockData.hideIfCoach) ||
        (!isCoach && (!blockData.isShown || blockData.hideIfPatient)));

    return blockData ? (
      <div
        ref={ref}
        key={`${taskKey}_${index}`}
        style={{
          width: "100%",
          margin: "0 auto",
          marginBottom: "8px",
          ...(taskWidth === "normal" && { maxWidth: "720px" }),
          ...(isHidden && { display: "none" }),
          ...(!blockData.isShown && { opacity: 0.5 }),
        }}
      >
        <div
          className={`${
            blockData.indentation && "flex items-start gap-[10px]"
          }`}
        >
          {blockData.indentation && <CommentArrowSvg />}

          <Sheet
            sx={{
              position: "relative",
              width: "100%",
              ...(!blockData.noBorder
                ? {
                    backgroundColor: "white",
                    borderWidth: "1px",
                    borderColor: "divider",
                    borderRadius: "12px",
                    px: "20px",
                    py: "16px",
                  }
                : {
                    backgroundColor: "transparent",
                    py: "12px",
                    pl: "10px",
                    ...(index > 0 && { pt: "24px" }),
                  }),
            }}
            className={` ${!isCoach && isDone && "pointer-events-none"}`}
          >
            {highlight && (
              <Box
                sx={{
                  backgroundColor: "primary.solid",
                  width: "5px",
                  height: "calc(100% - 40px)",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  transform: "translate(0, -50%)",
                }}
              ></Box>
            )}

            {blockData.lines?.map(
              (eachLine, lineIndex) =>
                !isLineHidden(data, blockData, eachLine) && (
                  <Line
                    key={`${taskKey}_line_${lineIndex}`}
                    lineStr={JSON.stringify(eachLine)}
                    lineIndex={lineIndex}
                    taskKey={taskKey}
                    thoughtRecordKey={thoughtRecordKey}
                    meditationRecordKey={meditationRecordKey}
                    activityRecordKey={activityRecordKey}
                    data={
                      eachLine.some(
                        (element) =>
                          element.type === "typographyFromData" ||
                          element.type === "pieChart" ||
                          element.type === "percentage" ||
                          element.type === "extractFromThoughtRecord"
                      )
                        ? data
                        : undefined
                    }
                    setData={setData}
                    blockIndex={index}
                    isLastLine={lineIndex === blockData.lines.length - 1}
                    translationVersion={translationVersion}
                    readonly={isDone || false}
                    isShown={blockData.isShown || false}
                    hasDivider={
                      lineIndex === 0 &&
                      blockData.lines[lineIndex].length === 1 &&
                      blockData.lines.length > 1 &&
                      [
                        "typography",
                        "typographyFromData",
                        "thoughtmap",
                        "preset",
                        "reference",
                        "conceptItem",
                        "isolation",
                      ].includes(blockData.lines[lineIndex + 1][0].type)
                    }
                  />
                )
            )}
            {!isCoach &&
              (isLastIndex || blockData.isEnd) &&
              !taskKey.includes("Tool") && (
                <Box sx={{ mt: "12px" }}>
                  <DoneButton
                    taskKey={taskKey}
                    data={data}
                    doneWithEnter={doneWithEnter}
                    setDoneWithEnter={setDoneWithEnter}
                    thoughtRecordKey={thoughtRecordKey}
                    activityRecordKey={activityRecordKey}
                    meditationRecordKey={meditationRecordKey}
                    isDone={isDone}
                  />
                </Box>
              )}
          </Sheet>
        </div>

        {!isLastIndex && !blockData.isEnd && (
          <>
            {!isCoach && !blockData.noArrow && (
              <PatientMenuBar
                highlight={
                  getTaskIdFromTaskKey(taskKey) === "0-0-A" && index === 0
                }
                disabled={
                  !(
                    isCurrentIndex &&
                    isBlockUserFieldFilled(blockData, data, user)
                  )
                }
                onClick={() => {
                  complete(index);
                }}
              />
            )}
            {isCoach &&
              (!blockData.noBorder || blockData.removable) &&
              !blockData.hideIfPatient && (
                <CoachMenuBar
                  taskKey={taskKey}
                  thoughtRecordKey={thoughtRecordKey}
                  setData={setData}
                  index={index}
                  isThoughtRecord={isThoughtRecord}
                  hasIndentation={blockData.indentation}
                  isRemovable={blockData.removable}
                  aiCommentKey={getAiCommentKeyInBlock(blockData)}
                />
              )}
          </>
        )}
      </div>
    ) : (
      <div />
    );
  }
);

export default memo(Block);
