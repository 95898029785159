import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  DividerLine,
  GuideTypographyLine,
  Percentage,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithPreset,
  InstructionWithTextarea,
} from "../BlockComponent";
import { Content_4_5_A_end, Content_4_5_A_start } from "./4-5-A";

export const Content_4_5_3_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),

  Header1(`typography_0_0_0`),
  InstructionWithContent(`typography_1_0_0`, [
    TypographyFromDataLine({ A: { id: "상황" } }, "$A$"),
  ]),
  // InstructionWithCoachTextarea(`typography_2_0_0`, {
  //   id: "점쟁이 예언",
  //   placeholder: "내담자가 하는 부정적 예상을 적어주세요.",
  // }),
  InstructionWithPreset(`typography_2_0_0`, {
    id: "점쟁이 예언",
    placeholder: "내담자가 하는 부정적 예상을 적어주세요.",
    presetKey: "negativePrediction",
  }),
  // InstructionWithCoachTextarea(`typography_3_0_0`, {
  //   id: "재앙화",
  //   placeholder: "내담자가 하는 파국적 결말을 적어주세요.",
  // }),
  InstructionWithPreset(`typography_3_0_0`, {
    id: "재앙화",
    placeholder: "내담자가 생각하는 파국적 결말을 적어주세요.",
    presetKey: "catastrophicEnding",
  }),
  InstructionWithDataWithContent(
    { A: { id: "재앙화" } },
    `typographyFromData_4_0_0`,
    [[Percentage({ id: "처음 재앙화 확률" })]]
  ),

  Header1(`typography_5_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "점쟁이 예언" } },
    `typographyFromData_6_0_0`
  ),
  InstructionWithDataWithContent(
    { A: { id: "점쟁이 예언" } },
    `typographyFromData_7_0_0`,
    [[Percentage({ id: "점쟁이 예언 확률" })]]
  ),

  // InstructionWithDataWithPreset(
  //   { A: { id: "점쟁이 예언" } },
  //   `typographyFromData_8_0_0`,
  //   {
  //     id: "무력화",
  //     placeholder:
  //       "더 이상 부정적으로 전개될 수 없도록 만드는 예상을 적어주세요.",
  //     presetKey: "predictionPreventBecomingMoreNegative",
  //   }
  // ),
  // InstructionWithDataWithContent(
  //   { A: { id: "점쟁이 예언" }, B: { id: "무력화" } },
  //   `typographyFromData_9_0_0`,
  //   [
  //     SolidLabelLine(`무력화 가능성`),
  //     TypographyFromDataLine({ A: { id: `무력화` } }, "$A$"),
  //     DimmedLabelLine("확률"),
  //     [Percentage({ id: "무력화 확률" })],
  //   ]
  // ),
  // InstructionWithData({ A: { id: `무력화` } }, `typographyFromData_10_0_0`),
  Instruction(`typographyFromData_10_0_0`),
  Instruction(`typography_10_1`),
  Instruction(`typography_10_2`),
  Instruction(`typography_10_3`),
  InstructionWithDataWithContent(
    { A: { id: "점쟁이 예언" }, B: { id: "재앙화" } },
    `typographyFromData_11_0_0`,
    [
      ...Array.from({ length: 6 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(each === 6 ? `최악의 가능성` : `다른 가능성`, {
          labelIndex: each === 6 ? undefined : each,
        }),
        each === 6
          ? TypographyFromDataLine({ A: { id: "재앙화" } }, "$A$")
          : TextareaLine({ id: `다른 가능성 ${each}` }),
      ]),
    ]
  ),
  InstructionWithContent("typography_12_0_0", [
    ...Array.from({ length: 6 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(each === 6 ? `최악의 가능성` : `다른 가능성`, {
        labelIndex: each === 6 ? undefined : each,
      }),
      TypographyFromDataLine(
        { A: { id: each === 6 ? "재앙화" : `다른 가능성 ${each}` } },
        "$A$"
      ),
      DimmedLabelLine("확률"),
      [
        Percentage({
          id: each === 6 ? "재앙화 확률" : `다른 가능성 ${each} 확률`,
          width: 0.4,
          requiredId: each === 6 ? "재앙화" : `다른 가능성 ${each}`,
          ...(each === 6 && {
            complementPercentageIdList: Array.from(
              { length: 5 },
              (v, i) => i + 1
            ).map((each) => `다른 가능성 ${each} 확률`),
          }),
        }),
      ],
    ]),

    GuideTypographyLine("typography_12_32_0", {
      width: 0.6,
      percentageIdList: Array.from({ length: 6 }, (v, i) => i + 1).map((each) =>
        each === 6 ? "재앙화 확률" : `다른 가능성 ${each} 확률`
      ),
    }),
  ]),

  Header1(`typography_13_0_0`),
  Instruction(`typography_14_0_0`),
  InstructionWithData(
    { A: { id: `점쟁이 예언 확률` }, B: { id: "점쟁이 예언" } },
    `typographyFromData_15_0_0`
  ),
  InstructionWithData(
    { A: { id: "점쟁이 예언 확률" }, B: { id: `점쟁이 예언` } },
    `typographyFromData_16_0_0`
  ),
  InstructionWithDataWithContent(
    { A: { id: "점쟁이 예언 확률" } },
    `typographyFromData_17_0_0`,
    [
      // SolidLabelLine(`무력화 가능성`),
      // TypographyFromDataLine({ A: { id: "무력화" } }, "$A$"),
      // DimmedLabelLine(`확률`),
      // TypographyFromDataLine(
      //   { A: { id: "점쟁이 예언 확률" }, B: { id: "무력화 확률" } },
      //   "typographyFromData_17_5_0"
      // ),
      ...Array.from({ length: 6 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(each === 6 ? `최악의 가능성` : `다른 가능성`, {
          labelIndex: each === 6 ? undefined : each,
        }),
        TypographyFromDataLine(
          { A: { id: each === 6 ? "재앙화" : `다른 가능성 ${each}` } },
          "$A$"
        ),
        DimmedLabelLine("확률"),
        TypographyFromDataLine(
          {
            A: { id: "점쟁이 예언 확률" },
            // B: { id: "무력화 확률" },
            C: { id: each === 6 ? `재앙화 확률` : `다른 가능성 ${each} 확률` },
          },
          "typographyFromData_17_10_0"
        ),
      ]),
    ]
  ),

  InstructionWithDataWithContent(
    { A: { id: "재앙화" } },
    "typographyFromData_18_0_0",
    [
      [
        Percentage({
          id: "나중 재앙화 확률",
        }),
      ],
    ]
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "처음 재앙화 확률" } },
    `typographyFromData_19_0_0`
  ),

  Header1(`typography_20_0_0`),
  InstructionWithDataWithContent(
    { A: { id: "재앙화" } },
    `typographyFromData_21_0_0`,
    [
      ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
        SolidLabelLine(`대처 전략`, { labelIndex: each }),
        TextareaLine({ id: `대처 전략 ${each}` }),
      ]),
    ]
  ),
  InstructionWithContent("typography_22_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`도움 대상`, { labelIndex: each }),
      TextareaLine({ id: `도움 대상 ${each}` }),
    ]),
  ]),
  InstructionWithTextarea(`typography_23_0_0`, { id: "강점" }),

  Header1(`typography_24_0_0`),
  Instruction(`typography_25_0_0`),
  InstructionWithData(
    { A: { id: "재앙화" }, B: { id: "나중 재앙화 확률" } },
    `typographyFromData_26_0_0`
  ),
  InstructionWithData({ A: { id: "강점" } }, `typographyFromData_27_0_0`),
  InstructionWithContent("typography_28_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`대처 전략`, { labelIndex: each }),
      TypographyFromDataLine({ A: { id: `대처 전략 ${each}` } }, "$A$"),
    ]),
  ]),
  InstructionWithContent("typography_29_0_0", [
    ...Array.from({ length: 3 }, (v, i) => i + 1).flatMap((each) => [
      SolidLabelLine(`도움 대상`, { labelIndex: each }),
      TypographyFromDataLine({ A: { id: `도움 대상 ${each}` } }, "$A$"),
    ]),
  ]),
  InstructionWithTextarea(`typography_30_0_0`),

  ...Content_4_5_A_end(automaticThoughtId, 3),
];
