import { useCallback, useEffect, useState } from "react";
import TaskHeader from "components/task/header/TaskHeader";
import TaskContent from "components/task/content/TaskContent";
import { Box, Stack } from "@mui/joy";
import { ProgramContentType } from "data/BlockComponent";
import * as _ from "lodash";
import RecordList from "./RecordList";
import { AI_COMMENT_DOMAIN, AI_COMMENT_LIST_ENDPOINT } from "api/aiApi";
import { generateRecordKey, isAllUserFieldFilled } from "logic/logics";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import useInterval from "hooks/useInterval";
import { useRecoilState, useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { patientAtom } from "recoil/patientAtom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userAtom } from "recoil/userAtom";
import useSetConceptData from "hooks/useSetConceptData";
import useGetCertainEventFromApp from "hooks/useGetCertainEventFromApp";
import useGetMeditationRecordData from "hooks/useGetMeditationRecordData";
import {
  meditationRecordApi,
  MeditationRecordType,
  MEDITATION_RECORD_DOMAIN,
  MEDITATION_RECORD_LIST_ENDPOINT,
} from "api/meditationRecordApi";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import Statistics from "./Statics";
import { useNavigate, useSearchParams } from "react-router-dom";
import useIsInappWebview from "hooks/useIsInappWebview";
import useIsLatest from "hooks/useIsLatest";

export default function MeditationRecord({ taskKey }: { taskKey: string }) {
  const [searchParams] = useSearchParams();
  const currentMeditationRecordKey = searchParams.get("mrk");
  const patientState = useRecoilValue(patientAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  // const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const isInappWebview = useIsInappWebview();
  const isTaskContainerSmall = isInappWebview;
  const [data, setData] = useState<ProgramContentType[] | undefined>();
  const [prevData, setPrevData] = useState<ProgramContentType[] | undefined>();

  const [recordList, setRecordList] = useState<
    MeditationRecordType[] | undefined
  >();
  const navigate = useNavigate();
  const isLatest = useIsLatest();
  const currentTabSectionIndex = useRecoilValue(currentTaskSectionIndexAtom);
  const [taskSectionState, setTaskSectionState] =
    useRecoilState(taskSectionAtom);

  useEffect(() => {
    if (!isLatest && recordList) {
      if (
        taskSectionState &&
        taskSectionState[currentTabSectionIndex].currentMeditationRecordKey
      ) {
        const currentMeditationRecordKey =
          taskSectionState[currentTabSectionIndex].currentMeditationRecordKey ||
          "";

        setCurrentRecord(
          recordList.find(
            (element) =>
              element.meditationRecordKey === currentMeditationRecordKey
          ) || {
            meditationRecordKey: currentMeditationRecordKey,
          }
        );
        setTaskSectionState((taskSectionState) => {
          if (taskSectionState) {
            const copy = _.cloneDeep(taskSectionState);
            copy[currentTabSectionIndex] = {
              ...taskSectionState[currentTabSectionIndex],
              currentMeditationRecordKey: undefined,
            };
            return copy;
          }
          return taskSectionState;
        });
      }
    }
  }, [recordList, taskSectionState]);

  useEffect(() => {
    if (isLatest && recordList) {
      if (currentMeditationRecordKey) {
        setCurrentRecord(
          recordList.find(
            (element) =>
              element.meditationRecordKey === currentMeditationRecordKey
          ) || {
            meditationRecordKey: currentMeditationRecordKey,
          }
        );

        navigate(`${window.location.pathname}`, { replace: true });
      }
    }
  }, [recordList, currentMeditationRecordKey]);

  const [currentRecord, setCurrentRecord] = useState<
    MeditationRecordType | undefined
  >();
  const [isEmpty, setIsEmpty] = useState<boolean | undefined>(undefined);

  const [isStatisticsOpen, setIsStatisticsOpen] = useState<boolean>(false);

  function openStatistics() {
    setIsStatisticsOpen(true);
    setCurrentRecord(undefined);
    setData(undefined);
  }

  //기록 선택하면 통계 닫기
  useEffect(() => {
    if (currentRecord) {
      setIsStatisticsOpen(false);
    }
  }, [currentRecord]);

  const {
    data: fetchedData,
    refetch,
    isFetching,
  } = useGetMeditationRecordData({
    mrk: currentRecord?.meditationRecordKey,
    isFirst: isEmpty,
  });

  // 개념화노트, 생각지도 데이터 세팅
  const { isConceptNoteFetching, isConceptFetching } = useSetConceptData({
    taskKey: taskKey,
  });

  useInterval(
    () => {
      setPrevData(data);
      //변화 없으면 다시 가져와
      if (data && prevData && _.isEqual(data, prevData)) {
        refetch();
      }
    },
    10000,
    []
  );

  const refetchActivityEvent = useGetCertainEventFromApp({
    eventName: "refetch_activity",
  });

  useEffect(() => {
    if (refetchActivityEvent) {
      refetch();
    }
  }, [refetchActivityEvent]);

  //기록 추가한 직후
  useEffect(() => {
    if (
      currentRecord &&
      !recordList?.find(
        (thoughtRecord) =>
          thoughtRecord.meditationRecordKey ===
          currentRecord?.meditationRecordKey
      )
    ) {
      setRecordList((list) => [
        ...(list || []),
        { ...currentRecord, shouldBeDone: false },
      ]);
    }
  }, [currentRecord]);

  const getRecordListqueryFn = useCallback(
    () => meditationRecordApi.getList(`${patientState?.patientId}`),
    [patientState]
  );

  useQuery(
    [MEDITATION_RECORD_DOMAIN, MEDITATION_RECORD_LIST_ENDPOINT],
    getRecordListqueryFn,
    {
      enabled: !!patientState?.patientId,
      onSuccess: (data) => {
        const list = data.result?.map((each) => ({
          ...each,
          shouldBeDone: each?.isDone
            ? false
            : recordList?.find(
                (element) =>
                  element.meditationRecordKey === each.meditationRecordKey
              )?.shouldBeDone,
        }));
        if (
          currentRecord &&
          !list?.find(
            (record) =>
              record.meditationRecordKey === currentRecord?.meditationRecordKey
          )
        ) {
          list?.push({ ...currentRecord, shouldBeDone: false });
        }
        setRecordList(list);

        if (!list || list.length === 0) {
          setIsEmpty(true);
          if (!currentRecord) {
            setCurrentRecord({
              meditationRecordKey: generateRecordKey(
                patientState?.patientId || "",
                "meditation"
              ),
            });
          }
        } else {
          list.sort((a, b) => {
            return +new Date(b.date || "") - +new Date(a.date || "");
          });
          setIsEmpty(false);

          let currentIndex = 0;
          if (!currentRecord) {
            const incompleteDataIndex = isCoach
              ? list.findIndex((element) => element.isHighlight)
              : list.findIndex((element) => !element.isDone);
            currentIndex = Math.max(incompleteDataIndex, 0);
          } else {
            currentIndex = list.findIndex(
              (element) =>
                element.meditationRecordKey ===
                currentRecord.meditationRecordKey
            );
          }
          setCurrentRecord(list[currentIndex]);
        }
      },
    }
  );

  useEffect(() => {
    if (currentRecord) {
      if (!currentRecord.isDone && isAllUserFieldFilled(data, false)) {
        setRecordList((recordList) => {
          if (recordList) {
            const data_temp = _.cloneDeep(recordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.meditationRecordKey ===
                  currentRecord?.meditationRecordKey
              ),
              1,
              { ...currentRecord, shouldBeDone: true }
            );

            return data_temp;
          }
          return recordList;
        });
      } else {
        setRecordList((recordList) => {
          if (recordList) {
            const data_temp = _.cloneDeep(recordList);
            data_temp.splice(
              data_temp.findIndex(
                (element) =>
                  element.meditationRecordKey ===
                  currentRecord?.meditationRecordKey
              ),
              1,
              { ...currentRecord, shouldBeDone: false }
            );

            return data_temp;
          }
          return recordList;
        });
      }
    }
  }, [data]);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (fetchedData) {
      if (!_.isEqual(fetchedData.content, data)) {
        setData(fetchedData.content);
        queryClient.invalidateQueries([
          AI_COMMENT_DOMAIN,
          AI_COMMENT_LIST_ENDPOINT,
          currentRecord?.meditationRecordKey,
        ]);
      }
    }
  }, [fetchedData]);

  const [moveToIndex, setMoveToIndex] = useState<number | undefined>(undefined);

  return (
    <>
      <Box
        sx={{
          flex: "none",
          zIndex: 100,
          // px: { xs: "16px", sm: "24px" },
        }}
      >
        <TaskHeader
          taskKey={taskKey}
          meditationRecordKey={currentRecord?.meditationRecordKey}
          // title={`${dayjs(currentRecord?.date).format(
          //   t_common("MMM D일 ddd요일")
          // )}`}
          title={`명상훈련지`}
          contentData={data}
          setContentData={setData}
          setMoveToIndex={setMoveToIndex}
          isDone={currentRecord?.isDone}
          {...(isTaskContainerSmall && {
            extraContent: (
              <RecordList
                recordList={recordList}
                currentRecord={currentRecord}
                setCurrentRecord={setCurrentRecord}
                isStatisticsOpen={isStatisticsOpen}
                openStatistics={openStatistics}
              />
            ),
          })}
        />
      </Box>
      <Stack sx={{ flex: 1, overflow: "hidden" }} direction={"row"}>
        {!isTaskContainerSmall && (
          <RecordList
            recordList={recordList}
            {...(!isStatisticsOpen && { currentRecord: currentRecord })}
            setCurrentRecord={setCurrentRecord}
            isStatisticsOpen={isStatisticsOpen}
            openStatistics={openStatistics}
          />
        )}
        {isStatisticsOpen ? (
          <Statistics />
        ) : currentRecord?.meditationRecordKey ? (
          <Stack sx={{ flex: 1, overflow: "hidden" }}>
            <TaskContent
              taskKey={taskKey}
              meditationRecordKey={currentRecord?.meditationRecordKey}
              data={data}
              setData={setData}
              moveToIndex={moveToIndex}
              setMoveToIndex={setMoveToIndex}
              isDone={currentRecord?.isDone}
              translationVersion={fetchedData?.translationVersion}
              isConceptFetching={
                isConceptNoteFetching || isConceptFetching || isFetching
              }
            />
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
}
