import { IconButton, Stack, Typography } from "@mui/joy";
import ButtonWithModal from "components/common/ButtonWithModal";
import Link from "components/common/Link";
import { TypographyWithLinkSubstring } from "components/payment/QnA";
import useLinkData from "hooks/useLinkData";
import { useTranslation } from "react-i18next";

export default function CxButton() {
  const { subscriptionCancelLink } = useLinkData();

  return (
    <ButtonWithModal
      buttonText={""}
      size="lg"
      title={"문의사항은 여기로 보내주세요."}
      // subtitle={t_ui(
      //   "사용 중인 닉네임을 알려주시면 더 빠르게 처리해 드릴게요."
      // )}

      textAlign="center"
      render={
        <Link
          text={"고객 센터"}
          opacity={0.4}
          customSx={{ fontSize: "14px" }}
        />
      }
      content={
        <Stack
          alignItems={"center"}
          spacing={1}
          sx={{ width: "100%", color: "text.tertiary" }}
        >
          <a href="mailto:support@orwellhealth.org?subject=문의드립니다&body=닉네임:%0D%0A문의사항:">
            support@orwellhealth.org
          </a>
          <Typography>
            <TypographyWithLinkSubstring
              mainString={"환불 관련 문의는 환불 접수하기를 확인해 주세요."}
              linkSubstrings={[
                {
                  linkString: "환불 접수하기",
                  url: "https://orwellhealth.notion.site/d07b7b28b8e04d858a93641249748b0f",
                },
              ]}
              linkOpenType="link"
            />
          </Typography>
        </Stack>
      }
    />
  );
}
