import { Box, Button, Sheet, Stack } from "@mui/joy";
import { useCallback, useEffect, useState } from "react";
import { taskApi } from "api";
import { useMutation } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { ProgramContentType } from "data/BlockComponent";
import PlainTextarea from "./textarea/PlainTextarea";
import { ElementType } from "data/CellComponent";
import { debounce } from "lodash";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import TextareaCell from "./TextareaCell";
import { theme } from "styles/theme";

export default function FeedbackCell({
  taskKey,
  savedFeedback,
  blockIndex,
  setData,
  lineIndex,
  cellIndex,
  editorKey,
}: {
  taskKey: string;
  savedFeedback?: string;
  blockIndex: number;
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  lineIndex: number;
  cellIndex: number;
  editorKey?: string;
}) {
  const userState = useRecoilValue(userAtom);
  const isCoach = userState?.role !== "patient";
  const [feedback, setFeedback] = useState<string>(savedFeedback || "");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const submitFeedbackQueryFn = useCallback(
    () => taskApi.submitFeedback(taskKey, feedback),
    [taskKey, feedback]
  );

  const { mutate: submitFeedback } = useMutation(submitFeedbackQueryFn, {
    onSuccess: () => {
      setFeedback("");
      handleChange("", true);
    },
  });

  const handleChangeTemp = (value: string) => {
    setFeedback(value);
  };
  const handleChange = (value: string, autoOpenNext?: boolean) => {
    setData((data) => {
      if (data && data[blockIndex] && data[blockIndex].lines) {
        const originalCurrentContent = (data[blockIndex].lines[lineIndex][
          cellIndex
        ].content || {}) as ElementType;
        const originalValue = originalCurrentContent.value || "";

        if (!(originalValue === value)) {
          const data_temp = _.cloneDeep(data);
          const currentContent =
            data_temp[blockIndex].lines[lineIndex][cellIndex].content || {};

          data_temp[blockIndex].lines[lineIndex][cellIndex].content = {
            ...currentContent,
            value: value,
            htmlString: `<p>${value}</p>`,
          };
          if (autoOpenNext) {
            data_temp[blockIndex].autoOpenNext = true;
          }

          return data_temp;
        }
        return data;
      }
      return data;
    });
  };

  const debouncedSaveText = debounce(handleChange, 500);

  useEffect(() => {
    debouncedSaveText(feedback);

    // 컴포넌트가 언마운트될 때 디바운스 함수를 취소합니다.
    return () => debouncedSaveText.cancel();
  }, [feedback, debouncedSaveText]);

  return (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ flex: 1, minWidth: "0px" }}>
        {/* minWidth:0 빼면 모바일에서 넘침 */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            px: "10px",
            py: "12px",
            borderRadius: "6px",
            boxShadow: isFocused
              ? `inset 0 0 0 1.5px ${theme.vars.palette.primary.solid}`
              : `inset 0 0 0 1px ${theme.vars.palette.divider}`,
          }}
        >
          <PlainTextarea
            placeholder={t_common("소감 placeholder")}
            initialValue={savedFeedback}
            handleChange={handleChangeTemp}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            editorKey={editorKey}
          />
        </Box>
      </Box>

      <Button
        disabled={isCoach || feedback?.length === 0}
        sx={{ px: "10px" }}
        onClick={() => {
          submitFeedback();
        }}
      >
        <Box
          sx={{
            width: "24px",
            height: "24px", //크기 지정 안하면 resize 어쩌구 에러
          }}
        >
          <span className="material-symbols-rounded">send</span>
        </Box>
      </Button>
    </Stack>
  );
}
