import TaskHeader from "components/task/header/TaskHeader";
import { Box } from "@mui/joy";
import MyThoughtMapContent from "./content/MyThoughtMapContent";
import PaymentModule from "components/sidebar/PaymentModule";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";

export default function MyThoughtMap({
  showPaymentModule,
}: {
  showPaymentModule: boolean;
}) {
  const { isNotStarted } = useSubscriptionStatus();

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"thoughtmap"} />
        {showPaymentModule && (
          <PaymentModule
            {...(isNotStarted && {
              title: "🤔 괴로운 생각은 어디서 비롯될까요?",
              subtitle: "스스로를 깊게 이해해 보세요.",
            })}
            type="header"
          />
        )}
      </Box>

      <div className="w-full h-full overflow-x-hidden">
        <MyThoughtMapContent />
      </div>
    </>
  );
}
