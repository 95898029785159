import { Button, Stack } from "@mui/joy";
import {
  paymentApi,
  PAYMENT_DOMAIN,
  PAYMENT_ORDER_DETAIL_ENDPOINT,
  PAYMENT_STEPPAY_REQUEST_ENDPOINT,
} from "api/paymentApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "recoil/userAtom";
import { sendMessage } from "utils/webview";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import useGetEventFromApp from "hooks/useGetEventFromApp";
import { useEffect, useState } from "react";
import { userApi, USER_DETAIL_ENDPOINT, USER_DOMAIN } from "api/userApi";
import ButtonWithModal from "../common/ButtonWithModal";
import { deepLinkData } from "data/deepLinkData";
import { isBrowser } from "react-device-detect";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import { useTranslation } from "react-i18next";
import { PATH } from "routes/paths";
import { subscriptionApi } from "api/subscriptionApi";

export default function PaymentButton({
  color = "primary",
  size = "lg",
}: {
  color?: "primary" | "white";
  size?: "sm" | "lg";
}) {
  const isInappWebview = useIsInappWebview();

  const { t: t_payment } = useTranslation("translation", {
    keyPrefix: "payment",
  });

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const user = useRecoilValue(userAtom);
  const paymentOrderState = useRecoilValue(paymentOrderAtom);
  // const setPaymentState = useSetRecoilState(paymentOrderAtom);
  const parsedMessageFromApp = useGetEventFromApp();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false); //중복 방지

  useEffect(() => {
    if (parsedMessageFromApp?.event === "payment") {
      const status = parsedMessageFromApp.params.status;
      if (status) {
        setIsLoading(false);
        if (status === "success" && !isDone) {
          queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
          sendMessage(
            JSON.stringify({
              event: "navigate_tab",
              params: {
                tabName: "코칭",
              },
            })
          );
          sendMessage(
            JSON.stringify({
              event: "navigate_back",
            })
          );
          setIsDone(true);
        }
      }
    }
  }, [parsedMessageFromApp]);

  // const { data: orderDetail } = useQuery(
  //   [PAYMENT_DOMAIN, PAYMENT_ORDER_DETAIL_ENDPOINT],
  //   () => paymentApi.getOrderDetail(),
  //   {
  //     enabled: !!user?.accessToken,
  //     onSuccess: (data) => {
  //       if (data && data.result) {
  //         setPaymentState(data.result);
  //       }
  //     },
  //   }
  // );
  const { refetch: requestSteppay } = useQuery(
    [PAYMENT_DOMAIN, PAYMENT_STEPPAY_REQUEST_ENDPOINT],
    () => paymentApi.requestSteppay(),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data && data.result) {
          setIsLoading(false);
          const orderCode = data.result?.steppayOrderCode;
          let url = new URL(
            `https://api.steppay.kr/api/public/orders/${orderCode}/pay`
          );
          url.search = new URLSearchParams(steppayParams).toString();
          if (isInappWebview) {
            //open_link: 사파리로 엶. 결제 끝나고 돌려보낼 수가 없음>탈락
            sendMessage(
              JSON.stringify({
                event: "request_steppay",
                params: {
                  url: String(url),
                },
              })
            );
          } else {
            // window.location.href = String(url);
            window.open(url, "_blank");
          }
        }
      },
    }
  );

  const { isNotStarted, hasFreeTrialUsed } = useSubscriptionStatus();
  const buttonText = t_payment(
    isNotStarted
      ? !hasFreeTrialUsed
        ? "무료 체험 시작하기"
        : "구독 시작하기"
      : "계속 이용하기"
  );

  // async function requestPayment() {
  //   const tossPayments = await loadTossPayments(clientKey);

  //   if (orderDetail && orderDetail.result) {
  //     tossPayments.requestPayment("카드", {
  //       amount: orderDetail.result.price,
  //       orderId: orderDetail.result.orderId,
  //       orderName: orderDetail.result.orderName,
  //       customerName: orderDetail.result.customerName,
  //       successUrl: `${window.location.origin}/payment/success`,
  //       failUrl: `${window.location.origin}/payment/fail`,
  //     });
  //   }
  // }

  //Steppay
  let steppayParams = {
    successUrl: `${window.location.origin}${PATH.steppayPaymentSuccess}`,
    errorUrl: `${window.location.origin}${PATH.steppayPaymentFail}`,
  };

  const { isCanceled } = useSubscriptionStatus();

  const { mutate: activateSubscription } = useMutation(
    () => subscriptionApi.activate(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER_DOMAIN, USER_DETAIL_ENDPOINT]);
        setIsLoading(false);
      },
    }
  );

  const disabled = !(
    !user?.subscription?.isSubscribing ||
    (paymentOrderState?.paymentProvider === "steppay" && isCanceled)
  );

  const requestPayment = () => {
    if (paymentOrderState) {
      if (paymentOrderState?.paymentProvider === "steppay") {
        if (isCanceled) {
          //구독 만료되기 전 취소, 재개하려는 경우
          setIsLoading(true);
          activateSubscription();
        } else {
          setIsLoading(true);
          requestSteppay();
        }
      } else if (paymentOrderState?.paymentProvider === "in_app") {
        if (isInappWebview) {
          if (paymentOrderState?.firstMonthOfferLinkIos) {
            sendMessage(
              JSON.stringify({
                event: "open_link",
                params: {
                  url: paymentOrderState.firstMonthOfferLinkIos,
                },
              })
            );
          } else {
            setIsLoading(true);
            sendMessage(
              JSON.stringify({
                event: "payment",
                params: {
                  productId: paymentOrderState?.productId,
                  offer: paymentOrderState?.offer,
                },
              })
            );
          }
        }
      }
    }
  };

  const { mutate: reassignCoach } = useMutation(() => userApi.reassignCoach(), {
    onSuccess: () => {},
  });

  return paymentOrderState?.paymentProvider === "in_app" &&
    !isInappWebview &&
    isBrowser ? (
    <ButtonWithModal
      size={size}
      color={color}
      buttonFullWidth
      buttonText={buttonText ?? ""}
      disabled={disabled}
      title={t_ui("앱에서 진행해주세요")}
      textAlign="center"
      content={
        <Stack alignItems={"center"} spacing={1}>
          <img
            src={deepLinkData.payment.qr}
            alt={"empty"}
            className="w-[100px] "
          />
        </Stack>
      }
    />
  ) : user?.isCoachChanged ? (
    <ButtonWithModal
      size={size}
      color={color}
      buttonFullWidth
      buttonText={buttonText ?? ""}
      disabled={disabled}
      loading={isLoading}
      title={"담당 코치가 변경되었어요"}
      subtitle={`${user?.assignedCoachName} 선생님은 더이상 디스턴싱과 함께하지 않게 되었어요.`}
      textAlign="center"
      actionText="새 코치 만나기"
      action={() => {
        reassignCoach();
        requestPayment();
      }}
    />
  ) : (
    <Button
      loading={isLoading}
      disabled={disabled}
      onClick={requestPayment}
      size={size}
      color={color}
      sx={{ width: "100%" }}
    >
      {buttonText}
    </Button>
  );
}
