import { Button, Stack, Typography } from "@mui/joy";
import Chip from "components/common/Chip";
import { Status } from "components/common/Status";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { paymentOrderAtom } from "recoil/paymentOrderAtom";
import { theme } from "styles/theme";
import { appLog } from "utils/webview";

export const planData = [
  {
    price: "240,000원",
    duration: "3개월",
    description: "첫 3개월 이후, 구독은 매 월 8만 원에 자동 갱신됩니다.",
    label: "환불 보장",
    productId: "orwellhealth.distancing.subscription10",
  },
  {
    price: "80,000원",
    duration: "1개월",
    description: "구독은 매 월 8만 원에 자동 갱신됩니다.",
    productId: "orwellhealth.distancing.subscription8",
  },
];

export default function SubscriptionPlan() {
  const [paymentOrderState, setPaymentOrderState] =
    useRecoilState(paymentOrderAtom);

  return (
    // <Stack
    //   sx={{
    //     width: "100%",
    //   }}
    //   spacing={"8px"}
    // >
    //   {planData.map(({ price, duration, description, label, productId }) => (
    //     <Button
    //       variant={"outlined"}
    //       color={
    //         productId === paymentOrderState.productId ? "primary" : "secondary"
    //       }
    //       onClick={() => {
    //         appLog(`click ${productId}`);
    //         setPaymentOrderState((state) => ({
    //           ...state,
    //           productId: productId,
    //         }));
    //       }}
    //       sx={{
    //         ...(productId === paymentOrderState.productId && {
    //           boxShadow: `inset 0 0 0 2px ${theme.vars.palette.primary.solid}`,
    //         }),
    //         borderRadius: "12px",
    //         px: "20px",
    //         py: "16px",
    //         textAlign: "left",
    //       }}
    //     >
    //       <Stack direction={"column"} sx={{ gap: "2px", width: "100%" }}>
    //         <Stack direction={"row"} alignItems={"center"} spacing="8px">
    //           <Typography level={"title-md"}>
    //             <Typography level={"h4"} fontWeight={600}>
    //               {price}
    //             </Typography>{" "}
    //             {`/ ${duration}`}
    //           </Typography>
    //           {label && <Chip label={label} />}
    //         </Stack>
    //         <Typography level={"body-sm"} sx={{ opacity: 0.6 }}>
    //           {description}
    //         </Typography>
    //       </Stack>
    //     </Button>
    //   ))}
    // </Stack>
    <Stack
      sx={{
        width: "100%",
        maxWidth: "400px",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "rgba(0, 0, 0, 0.3) 0 1px 3px",
        // borderWidth: "1px",
        // borderColor: "divider",
      }}
    >
      {planData.map(
        ({ price, duration, description, label, productId }, index) => (
          <Stack
            direction="row"
            alignItems={"center"}
            spacing="8px"
            sx={{
              p: "20px",

              ...(index === 0 && {
                borderBottomWidth: "1px",
                borderColor: "divider",
              }),
            }}
            onClick={() => {
              appLog(`click ${productId}`);
              setPaymentOrderState((state) => ({
                ...state,
                productId: productId,
              }));
            }}
          >
            <Stack direction={"column"} sx={{ gap: "2px", width: "100%" }}>
              <Stack direction={"row"} alignItems={"center"} spacing="8px">
                <Typography level={"title-md"} sx={{ whiteSpace: "nowrap" }}>
                  <Typography level={"h4"} fontWeight={600}>
                    {price}
                  </Typography>
                  {` / ${duration}`}
                </Typography>
                {label && <Chip label={label} />}
              </Stack>
              <Typography level={"body-xs"} sx={{ opacity: 0.6 }}>
                {description}
              </Typography>
            </Stack>
            <Stack
              sx={{
                flexShrink: 0,
              }}
            >
              <Status isChecked={productId === paymentOrderState.productId} />
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
}
