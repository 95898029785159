import { useEffect } from "react";
import DefaultPage from "./DefaultPage";
import { CircularProgress, Stack } from "@mui/joy";
import useLogin from "hooks/useLogin";

export default function KakaoLoginRedirect() {
  let params = new URL(document.URL).searchParams;
  let code = params.get("code");

  const login = useLogin({
    url: `/user/kakao/login`,
    requestBody: JSON.stringify({
      code: code,
      redirectUri:
        window.location.hostname === "localhost"
          ? "http://localhost:3000/oauth/kakao"
          : `${window.location.origin}/oauth/kakao`,
    }),
  });

  useEffect(() => {
    if (code) {
      login();
    }
  }, [code]);

  return (
    <DefaultPage
      content={
        <Stack alignItems={"center"}>
          <CircularProgress color="neutral" />
        </Stack>
      }
    />
  );
}
