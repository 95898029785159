import SubscriptionPlanOptionModal from "./SubscriptionPlanOptionModal";
import Link from "components/common/Link";
import { useState } from "react";
import ButtonWithModal from "components/common/ButtonWithModal";
import { isBrowser } from "react-device-detect";
import useIsInappWebview from "hooks/useIsInappWebview";
import { Stack } from "@mui/joy";
import { deepLinkData } from "data/deepLinkData";

export default function ShowAllSubscriptionPlanButton() {
  const [isPlanOpen, setIsPlanOpen] = useState<boolean>(false);
  const isInappWebview = useIsInappWebview();

  return !isInappWebview ? (
    <ButtonWithModal
      buttonFullWidth
      buttonText={""}
      title={"앱에서 진행해주세요"}
      textAlign="center"
      render={
        <Link
          text={"모든 플랜 보기"}
          onClick={() => {
            setIsPlanOpen(true);
          }}
        />
      }
      content={
        <Stack alignItems={"center"} spacing={1}>
          <img
            src={deepLinkData.payment.qr}
            alt={"empty"}
            className="w-[100px] "
          />
        </Stack>
      }
    />
  ) : (
    <>
      <SubscriptionPlanOptionModal
        isOpen={isPlanOpen}
        setIsOpen={setIsPlanOpen}
      />

      <Link
        text={"모든 이용권 보기"}
        // customSx={{ fontSize: "12px" }}
        onClick={() => {
          setIsPlanOpen(true);
        }}
      />
    </>
  );
}
