import { Box, Stack } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import CxButton from "./CXButton2";
import UserInfo from "components/user/UserInfo";

export type SidebarMenuType = "patient" | "task";

export default function MyPageModule() {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";

  return (
    <Box sx={{ mt: "20px" }}>
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        spacing="20px"
      >
        {user && <UserInfo user={user} />}
        {!isCoach && <CxButton />}
      </Stack>
    </Box>
  );
}
