import {
  DividerLine,
  LineWithDimmedLabel,
  LineWithSolidLabel,
  TextareaLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithContent,
  InstructionWithImage,
  Header1,
} from "../BlockComponent";
import { LastCard, MyWorryLines } from "./common";

export const Content_1_4_A = [
  Instruction(
    "그럼 생각과 나 사이의 거리가 좁을 때는 구체적으로 어떤 일이 발생하는 걸까요?"
  ),
  Instruction(
    "사람들은 마음속에 떠오르는 부정적인 생각에 대해 크게 세 가지 방식으로 대처하는데요. 하나씩 살펴볼게요."
  ),

  Header1("첫 번째 대처: 굴복"),
  Instruction(
    "생각과 나 사이의 거리가 좁을 때, 사람들은 자신의 마음속에 떠오른 부정적인 생각에 그대로 따르곤 해요."
  ),
  Instruction(
    "즉, '굴복'하는 대처란 그 생각이 말하고 있는 내용에 그대로 순응하는 것을 뜻합니다."
  ),
  Instruction(
    '"나는 무가치한 사람이야"라는 생각에 굴복하는 사람들은 실제 자신은 아무것도 할 수 없는 사람이라고 믿으며 좌절하고, 위축된 채 삶을 살아가게 되죠.'
  ),
  Instruction(
    '한편 "나 때문에 사람들이 피해를 봤다"라는 생각에 굴복하는 사람들은 끊임없이 옛 기억을 곱씹고 죄책감을 느끼며 무한히 가라앉기도 해요.'
  ),
  Instruction(
    "이처럼 마음속에 떠오른 부정적인 생각에 굴복하는 방식으로 대처하는 건 우리에게 도움이 되지 않아요."
  ),

  Header1("두 번째 대처: 과잉 보상"),
  Instruction(
    "생각과 나 사이의 거리가 좁을 때, 사람들은 자신의 마음속에 떠오른 부정적인 생각이 옳지 않다는 것을 증명해 보이기 위해 반대 방향으로 노력하곤 해요."
  ),
  Instruction(
    "즉, '과잉 보상'하는 대처란 그 생각이 틀렸음을 보이기 위해 그 생각과 정반대로 반응하는 것을 뜻합니다."
  ),
  Instruction(
    '"나는 무가치한 사람이야"라는 생각에 과잉 보상하는 사람들은 그것이 아님을 증명하고자 "항상 더 열심히 해야 한다"라는 원칙을 세우고 삶을 살아가곤 해요.'
  ),
  Instruction(
    "이러한 원칙은 삶이 원동력이 될 수 있겠으나, '과잉' 보상이라는 이름에서 알 수 있듯, 보통 과하게 작동한답니다."
  ),
  Instruction(
    '그래서 조금이라도 열심히 하지 못하는 순간을 마주하게 될 때면 스스로를 비난하고 자책하고, 결국엔 다시 "나는 무가치한 사람이야"라는 생각은 더욱 강해져만 가죠.'
  ),
  Instruction(
    '한 가지 예시만 더 살펴볼까요? "나는 사랑 받을 수 없다"라는 생각에 과잉 보상하는 사람들은 그것이 아님을 증명하고자 인간관계에 집착하곤 해요.'
  ),
  Instruction(
    `스스로가 사랑받을 수 있는 사람이라는 것을 증명하기 위해, 상대에게 지나치게 친절을 베풀거나 상대의 반응을 주시하며 안정과 사랑을 확인해요.`
  ),
  Instruction(
    `하지만 역시 그러한 과잉 보상은 과해지기 마련이어서, 불편한 인간관계를 만들며 상대와 멀어지고 결국 "나는 사랑 받을 수 없다"는 생각은 더욱 강해져 버려요.`
  ),
  Instruction(`이처럼 과잉 보상은 궁극적으로는 우리를 더 갉아먹어요.`),
  Instruction(
    `얼핏 보면 마치 우리를 더 노력하게 만들어 도움이 되는 것처럼 보이는 과잉 보상 역시 우리에게 그다지 도움이 되지 않아요.`
  ),

  Header1("세 번째 대처: 회피"),
  Instruction(
    `생각과 나 사이의 거리가 좁을 때, 사람들은 흔히 마음속에 떠오른 부정적인 생각이 존재하지 않는 것처럼 여기며 마주하기를 피해요.`
  ),
  Instruction(
    `즉, "회피"하는 대처란 그 생각을 외면하기 위해 주의를 딴 곳으로 돌려버리거나 마음에서 몰아내는 것을 뜻합니다.`
  ),
  Instruction(
    `"나는 잘하는 게 없다"라는 생각을 회피하는 사람들은 애초에 어려운 과제를 하지 않음으로써 그 생각을 마주하지 않으려 하곤 해요.`
  ),
  Instruction(
    `잠시 동안은 괜찮을 거예요. 하지만 이러한 회피가 계속 쌓이다 시간이 흘러 뒤를 돌아보면, 결국 아무것도 해낸 게 없다는 사실에 결국 다시 "나는 잘하는 게 없다"라는 생각은 더욱 강해져만 가죠.`
  ),
  Instruction(
    `한 가지 예시만 더 살펴볼까요? "삶은 아무런 의미가 없다"라는 생각을 회피하는 사람들은 애초에 아무것도 하지 않음으로써 그 생각을 마주하려는 것을 피하곤 해요.`
  ),
  Instruction(
    `하지만 점차 활동 반경이 줄어들면서 의미를 찾을 기회들도 모두 차단하죠. 결국 하루 종일 무기력하게 침대에만 누워 있게 되며 결국 "삶은 아무런 의미가 없다"라는 생각은 더욱 강해져만 가게 된답니다.`
  ),
  Instruction(
    `이처럼 생각에 굴복하고, 부정하고, 외면하는 모든 노력은 분명 효과적이지 않아요.`
  ),

  Header1("지난 나의 대처 돌아보기"),
  Instruction(
    `세 가지 대처에 대해 공부를 마쳤으니, 이번엔 나의 문제를 두고 얘기해볼게요.`
  ),
  InstructionWithContent(
    `지난날, 나는 나의 문제에 맞서 어떠한 대처를 하려고 했었나요?`,
    [
      ...MyWorryLines(),
      ...[{ label: "굴복" }, { label: "과잉 보상" }, { label: "회피" }].flatMap(
        ({ label }) => [
          ...LineWithSolidLabel(label, TextareaLine({ id: label })),
        ]
      ),
    ]
  ),
  Instruction(`아마도 얼핏 보면 꽤 합리적인 대처 방법이었던 것 같기도 해요.`),
  Instruction(
    `하지만 어땠나요? 그런 대처들로 문제가 해결되었나요? 문제가 작아지기는커녕, 점점 더 곪아 깊어지진 않았을까요?`
  ),
  InstructionWithContent(`각각의 대처에 대한 결과는 어땠나요?`, [
    ...MyWorryLines(),
    ...[{ label: "굴복" }, { label: "과잉 보상" }, { label: "회피" }].flatMap(
      ({ label }) => [
        ...LineWithSolidLabel(
          label,
          TypographyFromDataLine({ A: { id: label } }, "$A$")
        ),
        ...LineWithDimmedLabel("결과", TextareaLine()),
      ]
    ),
  ]),
  Instruction(
    `좋아요. 이제부터 우리는 문제를 더욱 효과적으로 해결하는 법에 대해 배우고 터득해 나갈 거예요. 위에 적었던 굴복하고, 과잉 보상하고, 회피하는 방식 말고요.`
  ),

  Header1("다시, 생각과 팝콘과 나"),
  InstructionWithImage(
    "잠시 앞서 살펴본 팝콘 기계의 예시를 떠올려 볼까요? 우리의 마음은 정확히 팝콘 기계처럼 동작한다고 배웠어요.",
    "0.png"
  ),
  Instruction("탁, 탁. 팝콘 기계는 불규칙적으로 팝콘을 만들고 있네요."),
  InstructionWithImage(
    "팝콘 기계 속에는 하얀 팝콘도 있고, 때론 검은 팝콘도 있습니다.",
    "1.png"
  ),
  Instruction("편의상 우리를 힘들게 하는 생각을 검은 팝콘이라고 해볼게요."),
  Instruction("검은 팝콘은 그다지 우리 마음에 들지는 않겠죠."),
  InstructionWithImage(
    "이때 누군가는 검은 팝콘을 잔뜩 쌓아두고 그것만 바라보며 삶을 살아가곤 합니다(=굴복).",
    "2.png"
  ),
  InstructionWithImage(
    "또 누군가는 검은 팝콘이 사실은 검은색이 아니라는 것을 증명하기 위해 애쓰며 삶의 대부분을 보내곤 합니다(=과잉 보상).",
    "3.png"
  ),
  InstructionWithImage(
    "또 다른 누군가는 팝콘 기계 속에 검은 팝콘이 있다는 사실을 부정하며 애써 그것을 바라보지 않으려고, 또는 어떻게든 그걸 보이지 않도록 하려고 애씁니다(=회피).",
    "4.png"
  ),
  Instruction(
    "하지만 앞서 배웠듯, 우린 스스로의 의지대로 생각할 수 없어요. 팝콘은 무작위로 튀어 오를 뿐이죠."
  ),
  Instruction(
    "그렇기에 팝콘 기계에서 튀어 오르는 팝콘 중엔 어쩔 수 없이 검은 팝콘이 포함되어 있을 수밖에 없답니다."
  ),
  Instruction("그럼 우리는 검은 팝콘을 두고 무엇을 할 수 있을까요?"),
  Instruction(
    "튀어 오른 팝콘이 검은 팝콘이라는 것을 알아차린 뒤 기꺼이 팝콘을 놓아주어야 해요. 앞서 배운 세 가지 대처로 반응하지 않고요."
  ),
  InstructionWithImage(
    "그러기 위해서는 '거리두기'를 통해 생각과 나 사이에 '공간'을 만들 필요가 있어요.",
    "5.png"
  ),
  Instruction(
    `충분한 공간이 없으면 우리는 끊임없이 검은 팝콘에 대해 대처 반응을 보이기 때문이에요. 나도 모른 채 아주 반사적이고 자동적으로요.`
  ),
  Instruction(
    `그럼 우선 어떤 것이 검은 팝콘인지 식별할 수 있는 안목을 가져야 할 거예요.`
  ),
  Instruction(
    `앞으로 우린 관찰자 시점에서 생각을 제3의 대상처럼 다루며 검은 팝콘을 분간하는 능력을 기르게 될 거예요.`
  ),
  Instruction(
    `검은 팝콘에 굴복하지 않고, 과잉 보상하지 않고, 애써 외면하며 회피하지 않고, 그 생각이 내 마음속에 있다는 사실을 기꺼이 인정하고 경험하면서 말이죠.`
  ),
  Instruction(`이것이 사실상 이 프로그램의 전부입니다.`),
  Instruction(
    `여러분은 이 과정만으로도 마음속에 떠오르는 부정적인 생각이 나를 괴롭히는 정도를 확연히 줄일 수 있을 거예요.`
  ),
  Instruction(
    `그리고 때가 되면 우리는 당신에게 하얀 팝콘이 무엇인지, 그것을 어떻게 찾고 의도를 갖고 집을 수 있는지 알려드릴게요.`
  ),

  InstructionWithImage(
    "그러면 당신은 점차 당신이 거리두기를 통해 만든 공간에 당신이 원하는 하얀 팝콘을 조금씩 채워 나갈 수 있을 거예요.",
    "6.png"
  ),

  Header1("괴로움은 나의 선택"),
  Instruction(
    `거리두기의 원리를 잘 표현하는 문장이 있어 이를 소개하며 이 활동을 마칠게요.`
  ),
  Instruction(`"고통은 불가피하지만 괴로움은 선택이다."`),
  Instruction(`사람들은 '왜 다들 나만 이렇게 힘든 걸까?' 생각하곤 해요.`),
  Instruction(`한 가지 비밀을 알려드릴게요.`),
  Instruction(`모두가 '고통'스럽습니다.`),
  Instruction(`나도 힘들고, 너도 힘듭니다.`),
  Instruction(`삶의 과정에서 우리 모두 사랑하는 사람을 잃기도 하고`),

  Instruction(`부모님을 떠나보내기도 하고`),
  Instruction(`때론 자식을 더 먼저 떠나보내기도 하고`),
  Instruction(`직장에서 모멸감과 수치심을 느끼기도 하고`),
  Instruction(`엉켜버릴 대로 엉킨 인간관계를 포기해야 할 순간도 겪어요.`),
  Instruction(`하지만 모두가 그 고통에 대해 '괴로움'을 느끼는 건 아닐 거예요.`),
  Instruction(
    `삶의 다양한 과정에서 마음속에 떠오르는 부정적인 생각을 없애버릴 수는 없어요. 불가능하죠.`
  ),
  Instruction(
    `하지만 그 생각에 굴복하며, 과잉 보상하며, 회피하며 오랜 기간 괴로워할지는 우리 선택에 달렸어요.`
  ),
  Instruction(`누군가는 고통 속에서도 고통에 과하게 반응하지 않은 채`),
  Instruction(
    `자신이 원하는 삶의 형태와 중요하게 여기는 가치를 명료하게 한 뒤,`
  ),
  InstructionWithImage("자신이 중심이 되어 삶을 살아나가고 있어요.", "7.png"),
  Instruction(`우리는 당신도 그렇게 될 수 있다고 믿습니다.`),
  Instruction(`자, 그러면 이제 본격적으로 거리두기를 연습해볼까요?`),
  ...LastCard,
];
