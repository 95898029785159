import {
  DividerLine,
  Select,
  SolidLabelLine,
  TextareaLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Content,
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithData,
  InstructionWithDataWithContent,
  InstructionWithDataWithTempContent,
} from "../BlockComponent";
import { LastCard } from "./common";

export const Content_104_0_A = (
  activityId: number,
  isFromActivityRecord: boolean,
  candidateList: string[]
) => [
  InstructionWithDataWithTempContent({ N: { id: "N" } }, `typography_0_0_0`, [
    [
      {
        type: "temp",
        content: {
          id: `N`,
          translationKey: `${activityId}`,
        },
      },
    ],
  ]),
  Header1(`typography_1_0_0`),
  Instruction("typography_2_0_0"),
  Instruction("typography_3_0_0"),
  Instruction("typography_4_0_0"),
  ...(isFromActivityRecord
    ? [
        InstructionWithContent(
          `typography_5_0_0`,
          [
            [
              Select(
                candidateList.map((each) => ({ text: each })),

                {
                  id: "activity",
                }
              ),
            ],
          ],
          {}
        ),
      ]
    : [
        Instruction("typography_6_0_0"),
        Instruction("typography_7_0_0"),
        InstructionWithContent(
          `typography_8_0_0`,
          [
            [
              Select(
                candidateList.map((each) => ({ text: each })),

                {
                  id: "activity",
                }
              ),
            ],
          ],
          {}
        ),
      ]),

  Header1(`typography_9_0_0`),
  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`),

  Instruction(`typography_13_0_0`),
  InstructionWithContent(`typography_13_0_0`, [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((M) => [
      SolidLabelLine(`단계`, { prefixLabelIndex: M }),
      TypographyLine(`typography_13_${M}_0`),
    ]),
  ]),

  Instruction(`typography_13_0_0_1`),
  InstructionWithContent(`typography_13_0_0_2`, [
    ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((M) => [
      SolidLabelLine(`단계`, { prefixLabelIndex: M }),
      TypographyLine(`typography_13_${M}_0_2`),
    ]),
  ]),
  Instruction(`typography_13_0_0_3`),
  Instruction(`typography_13_0_0_4`),
  Instruction(`typography_13_0_0_5`),
  Instruction(`typography_13_0_0_6`),

  Instruction(`typography_14_0_0`),
  Instruction(`typography_15_0_0`),
  Instruction(`typography_16_0_0`),
  Instruction(`typography_17_0_0`),
  Instruction(`typography_18_0_0`),
  InstructionWithDataWithContent(
    { A: { id: "activity" } },
    `typography_19_0_0`,
    [
      ...Array.from({ length: 5 }, (v, i) => i + 1).flatMap((M) => [
        SolidLabelLine(`단계`, { prefixLabelIndex: M }),
        TextareaLine({ id: `step_${M}` }),
      ]),
    ]
  ),
  Instruction(`typography_20_0_0`),
  Instruction(`typography_21_0_0`),
  ...LastCard,
];
