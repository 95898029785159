import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PATH } from "./paths";
import Login from "pages/Login";
import Home_Old from "pages/Home";
import Home from "pages/Home2";
import KakaoLoginRedirect from "pages/KakaoLoginRedirect";
import Header from "components/header/Header";
import Waiting from "pages/Waiting";
import Survey from "pages/Survey";
import PaymentFail from "../pages/PaymentFail";
import PaymentSuccess from "../pages/PaymentSuccess";
import { useEffect } from "react";
import { BUILD_TARGET } from "config";
import Settings from "components/common/Settings";
import AppleLoginRedirect from "pages/AppleLoginRedirect";
import "translation/i18n";
import SteppayPaymentSuccess from "pages/SteppayPaymentSuccess";
import StepPayPaymentFail from "pages/SteppayPaymentFail";
import SteppaySubscriptionManager from "pages/SteppaySubscriptionManager";
import SelfCheck from "pages/SelfCheck";
import SelfCheckEnd from "pages/SelfCheckEnd";
import SelfCheckWebflow from "pages/SelfCheckWebflow";
import EmailAuthResultPage from "pages/user/EmailAuthResultPage";
import EmailAuthPage from "pages/user/EmailAuthPage";
import InstitutionSelectPage from "pages/user/InstitutionSelectPage";
import Tool from "pages/tool/self-distancing/Tool";
import MeditationTool from "pages/tool/meditation/MeditationTool";
import ThoughtRecordTool from "pages/tool/thought-record/ThoughtRecordTool";
import Recording from "pages/tool/thought-record/Recording";
import Isolation from "pages/tool/thought-record/Isolation";
import Test from "pages/tool/thought-record/Test";
import TestSpeechToText from "pages/tool/thought-record/TestSpeechToText";
import ThoughtTrapList from "pages/tool/thought-record/ThoughtTrapList";
import Onboarding from "pages/onboarding/Onboarding";
import useIsInappWebview from "hooks/useIsInappWebview";
import TaskPage from "pages/TaskPage";
import { GlobalStyles } from "@mui/joy";
import useIsLatest from "hooks/useIsLatest";
import OnboardingBeforeLogin from "pages/onboarding/OnboardingBeforeLogin";

export default function Router() {
  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    function copytoclipboard(val) {
      var t = document.createElement("textarea");
      document.body.appendChild(t);
      t.value = val;
      t.select();
      document.execCommand("copy");
      document.body.removeChild(t);
    }
    function inappbrowserout() {
      copytoclipboard(window.location.href);
      alert(
        'URL주소가 복사되었습니다.\n\nSafari가 열리면 주소창을 길게 터치한 뒤, "붙여놓기 및 이동"를 누르면 정상적으로 이용하실 수 있습니다.'
      );
      window.location.href = "x-web-search://?";
    }

    var useragt = navigator.userAgent.toLowerCase();
    var target_url = window.location.href;
    const closeKakaotalkBrowser = () => {
      var _ua = window.navigator.userAgent;
      const isIOS = /iPad|iPhone|iPod/.test(_ua);

      window.location.href = isIOS
        ? "kakaoweb://closeBrowser"
        : "kakaotalk://inappbrowser/close";
    };

    if (useragt.match(/kakaotalk/i)) {
      //카카오톡 외부브라우저로 호출
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(target_url);
      setTimeout(() => {
        var mobile = document.createElement("meta");
        mobile.name = "viewport";
        mobile.content =
          "width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, minimal-ui";
        document.getElementsByTagName("head")[0].appendChild(mobile);
        document.body.innerHTML = `
        <style>body{background-color:#EEF2F3; margin:0; padding:0; overflow:hidden; width:100%; height: 100%; display:flex; justify-content:center; align-items:center}</style>
        <div style='display:flex; justify-content:center'>
        <svg
        width="256"
        height="256"
        viewBox="0 0 256 256"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 56H120V72H200V88H248V168H200V200H184L152 168H120H40V56ZM40 168H8V88H40V168Z"
          fill="#E2E6E8"
        />
      </svg></div>`;
      }, 100);
    } else if (useragt.match(/line/i)) {
      //라인 외부브라우저로 호출
      if (target_url.indexOf("?") !== -1) {
        window.location.href = target_url + "&openExternalBrowser=1";
      } else {
        window.location.href = target_url + "?openExternalBrowser=1";
      }
    } else if (
      useragt.match(
        /inapp|naver|snapchat|wirtschaftswoche|thunderbird|instagram|everytimeapp|whatsApp|electron|wadiz|aliapp|zumapp|iphone(.*)whale|android(.*)whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|trill|SamsungBrowser\/[^1]/i
      )
    ) {
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDev = BUILD_TARGET === "dev";

  if (!isDev) {
    // 리얼에서 사용할 수 없도록
    var console = window.console || {};
    console.log = function no_console() {}; // console log 막기
    console.warn = function no_console() {}; // console warning 막기
    console.error = function () {}; // console error 막기
  }

  const isInappWebview = useIsInappWebview();
  const isLatest = useIsLatest();
  return (
    <BrowserRouter>
      <div
        className="w-screen h-screen flex flex-col "
        style={{
          height: "calc(var(--vh,1vh) * 100)",
          minHeight: "calc(var(--vh,1vh) * 100)",
          whiteSpace: "pre-line",
          ...(isInappWebview && {
            "-webkit-user-select": "none" /* Safari, Chrome */,
            "-moz-user-select": "none" /* Firefox */,
            "-ms-user-select": "none" /* IE/Edge */,
            "user-select": "none",
          }),
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": isInappWebview
                ? `${window.innerWidth}px`
                : "320px",
              "--StickyHeader-height": "60px",
              "--InsideC-width": isInappWebview
                ? `${window.innerWidth}px`
                : "360px",
            },
          })}
        />
        <Settings>
          {/* <Header /> */}
          <div className="flex-1 flex  overflow-hidden">
            <Routes>
              <Route path={PATH.login} element={<Login />} />
              <Route path={PATH.waiting} element={<Waiting />} />
              <Route path={PATH.onboarding} element={<Onboarding />} />
              <Route
                path={PATH.onboardingBeforeLogin}
                element={<OnboardingBeforeLogin />}
              />

              <Route
                path={PATH.home}
                element={isLatest ? <Home /> : <Home_Old />}
              />
              <Route path={PATH.task} element={<TaskPage />} />
              <Route path={PATH.redirect} element={<KakaoLoginRedirect />} />
              <Route
                path={PATH.redirect_apple}
                element={<AppleLoginRedirect />}
              />
              <Route path={PATH.survey} element={<Survey />} />
              <Route path={PATH.paymentSuccess} element={<PaymentSuccess />} />
              <Route path={PATH.paymentFail} element={<PaymentFail />} />

              <Route
                path={PATH.steppayPaymentSuccess}
                element={<SteppayPaymentSuccess />}
              />
              <Route
                path={PATH.steppayPaymentFail}
                element={<StepPayPaymentFail />}
              />
              <Route
                path={PATH.steppayManagement}
                element={<SteppaySubscriptionManager />}
              />

              <Route path={PATH.selfCheck} element={<SelfCheck />} />
              <Route path={PATH.selfCheckEnd} element={<SelfCheckEnd />} />

              {isDev && (
                <Route
                  path={PATH.selfCheckWebflow}
                  element={<SelfCheckWebflow />}
                />
              )}
              <Route
                path={PATH.institutionSelect}
                element={<InstitutionSelectPage />}
              />
              <Route path={PATH.emailAuth} element={<EmailAuthPage />} />
              <Route
                path={PATH.emailAuthResult}
                element={<EmailAuthResultPage />}
              />

              <Route path={PATH.tool} element={<Tool />} />
              <Route path={PATH.meditationTool} element={<MeditationTool />} />

              <Route
                path={PATH.thoughtRecordTool}
                element={<ThoughtRecordTool />}
              />
              <Route
                path={PATH.thoughtRecordTool_recording}
                element={<Recording />}
              />
              <Route
                path={PATH.thoughtRecordTool_isolation}
                element={<Isolation />}
              />
              <Route
                path={PATH.thoughtRecordTool_thoughtTrap}
                element={<ThoughtTrapList />}
              />

              {isDev && <Route path={PATH.test} element={<Test />} />}
              <Route
                path={PATH.testSpeechToText}
                element={<TestSpeechToText />}
              />
            </Routes>
          </div>
        </Settings>
      </div>
    </BrowserRouter>
  );
}
