import { Box, Stack, Typography, useColorScheme } from "@mui/joy";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";

export function FreeTrialInstruction() {
  return (
    <Typography level="body-md" sx={{ fontWeight: 700 }}>
      7일 이내 취소 시 &nbsp;
      <Typography
        level="body-md"
        sx={{ fontWeight: 700, color: "primary.solid" }}
      >
        100% 무료
      </Typography>
    </Typography>
  );
}

export default function Price() {
  const { hasFreeTrialUsed } = useSubscriptionStatus();
  function Container({
    text,
    price,
    isHighlight,
  }: {
    text: string;
    price: string;
    isHighlight: boolean;
  }) {
    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{
          width: "100%",
          py: "20px",
          px: "20px",
          ...(!isHighlight
            ? { backgroundColor: "background.level1" }
            : { borderWidth: "2px", borderColor: "primary.solid" }),
          borderRadius: "12px",
        }}
      >
        <Typography
          level="body-md"
          sx={{
            fontWeight: 700,

            ...(!isHighlight && {
              opacity: 0.2,
              textDecoration: "line-through",
            }),
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            ...(!isHighlight && {
              opacity: 0.2,
              textDecoration: "line-through",
            }),
          }}
        >
          {price}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack sx={{ width: "100%" }} spacing={"10px"} alignItems="center">
      {[
        { text: "심리상담 4회기", price: "약 40만원", isHighlight: false },
        { text: "디스턴싱 데일리 케어", price: "월 8만원", isHighlight: true },
      ].map(({ text, price, isHighlight }) => (
        <Container text={text} price={price} isHighlight={isHighlight} />
      ))}

      {!hasFreeTrialUsed && <FreeTrialInstruction />}
    </Stack>
  );
}
