import { useCallback, useEffect } from "react";
import { axiosInstance, setAuthHeaders } from "api";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "recoil/userAtom";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { patientAtom } from "recoil/patientAtom";
import {
  Box,
  Button,
  Divider,
  GlobalStyles,
  Sheet,
  Stack,
  useTheme,
} from "@mui/joy";
import { getRedirectPath } from "logic/logics";
import { useMediaQuery } from "@mui/material";
import { taskSectionAtom, TaskSectionType } from "recoil/taskSectionAtom";
import InsideC from "components/ai/InsideC";
import Sidebar from "components/sidebar/SideBar";
import { layoutAtom } from "recoil/layoutAtom";
import TaskSection from "components/task/TaskSection";
import "dayjs/locale/ko";
import dayjs from "dayjs";
import { taskListAtom } from "recoil/taskListAtom";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import LogoEmptyPage from "static/image/common/LogoEmptyPage";
import SidebarOpenButton from "components/common/SidebarOpenButton";
import { userApi, USER_DETAIL_ENDPOINT, USER_DOMAIN } from "api/userApi";
import { useQuery } from "@tanstack/react-query";
import duration from "dayjs/plugin/duration";
import useOpenTask from "hooks/useOpenTask";
import { sendMessage } from "utils/webview";
import { useTranslation } from "react-i18next";
import useRemoveTab from "hooks/useRemoveTab";
import useRefetchTaskList from "hooks/useRefetchTaskList";
import useSetConceptData from "hooks/useSetConceptData";
import useIsInappWebview from "hooks/useIsInappWebview";
import useIsLatest from "hooks/useIsLatest";
import FullPageWithCenterContent from "./FullPageWithCenterContent";
import UpdateScreen from "./UpdateScreen";

export default function Home() {
  const navigate = useNavigate();
  const goOnboarding = () => {
    navigate("/onboarding/1", { replace: true });
  };
  const { state } = useLocation();
  const isOnboarding = state?.isOnboarding || false;

  const [searchParams] = useSearchParams();
  const isPayment = searchParams.get("tab") === "payment";
  const openPayment = useOpenTask("payment");
  const openThoughtmap = useOpenTask("thoughtmap");
  const isInappWebview = useIsInappWebview();

  dayjs.extend(duration);

  const { t } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [user, setUser] = useRecoilState(userAtom);
  const [patientState, setPatientState] = useRecoilState(patientAtom);

  const isCoach = user?.accessToken && user?.role !== "patient";

  const taskSectionState = useRecoilValue(taskSectionAtom);
  const taskListState = useRecoilValue(taskListAtom);
  const isAllTaskDone = taskListState.every((element) => element.isDone);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const setLayoutState = useSetRecoilState(layoutAtom);

  const queryFn = useCallback(() => userApi.getDetail(), []);

  const { removeTab: removePayment } = useRemoveTab({ taskKey: "payment" });
  const refetchTaskList = useRefetchTaskList();

  // 개념화노트, 생각지도 데이터 세팅
  const { isConceptNoteFetching, isConceptFetching } = useSetConceptData();

  useQuery([USER_DOMAIN, USER_DETAIL_ENDPOINT], queryFn, {
    enabled:
      !!axiosInstance.defaults.headers["X-Auth-Token"] && !!user?.accessToken,

    onSuccess: (data) => {
      if (data && data.result) {
        if (data.result.role === "patient") {
          setPatientState({
            patientId: `${data.result.userId}`,
            nickname: data.result.nickname,
          });
        }
        //결제 탭 닫기
        if (data.result.subscription?.isSubscribing) {
          removePayment();

          //자물쇠 풀기
          refetchTaskList();
        }
        if (!isCoach && !data.result.isAcquisitionChannelSubmitted) {
          goOnboarding();
        }

        setUser((user) => ({
          ...user,
          ...data.result,
        }));
      }
    },
  });

  useEffect(() => {
    if (user && user.accessToken) {
      setAuthHeaders(user.accessToken);
      sendMessage(
        JSON.stringify({
          event: "value",
          params: {
            accessToken: user.accessToken,
          },
        })
      );
      if (isPayment) {
        openPayment();
      }
    } else {
      if (!isOnboarding) {
        navigate(getRedirectPath(user), { ...state, replace: true });
      }
    }
  }, [user?.accessToken]);

  useEffect(() => {
    if (patientState?.patientId && !isInappWebview) {
      openThoughtmap();
    }
  }, [patientState?.patientId]);

  // useEffect(() => {
  //   sendMessage(
  //     JSON.stringify({
  //       event: "ready",
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (!taskSectionState || taskSectionState?.length === 0) {
      setLayoutState((state) => ({ ...state, isLeftSideBarOpen: true }));
    }
  }, [isSmall, taskSectionState]);

  const needUpdate = false;

  return needUpdate ? (
    <UpdateScreen />
  ) : user?.accessToken || isOnboarding ? (
    <Sheet
      sx={{
        backgroundColor: "background.level2",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        whiteSpace: "pre-line",
      }}
      className="w-full h-full"
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": isSmall ? `${window.innerWidth}px` : "320px",
            "--StickyHeader-height": "60px",
            // "--InsideC-width": "360px",
            "--InsideC-width": isSmall ? `${window.innerWidth}px` : "360px",
          },
        })}
      />

      <Sidebar />

      <Stack
        sx={{
          flex: 1,
          backgroundColor: "background.level1",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minWidth: 0,
        }}
        divider={
          <Divider
            orientation="vertical"
            sx={{ height: "100%", alignSelf: "center", mx: 0 }}
          />
        }
      >
        {taskListState &&
          taskListState.length > 0 &&
          (taskSectionState && taskSectionState.length > 0 ? (
            taskSectionState.map((each: TaskSectionType, index: number) => (
              <TaskSection key={`taskSection${index}`} index={index} />
            ))
          ) : (
            <Box
              sx={{
                alignSelf: "center",
                mx: "auto",
                textAlign: "center",
                p: 5,
              }}
            >
              <Stack spacing={3} sx={{ maxWidth: "500px" }}>
                <TitleWithSubtitle
                  title={
                    isCoach
                      ? ""
                      : isAllTaskDone
                      ? `주어진 활동을 모두 마쳤어요`
                      : `좌측 사이드바에서 주어진 활동을 진행해주세요`
                  }
                  subtitle={
                    isCoach
                      ? ""
                      : isAllTaskDone
                      ? `코치 선생님이 확인 후에 메시지를 드릴 거예요.`
                      : `'오늘의 활동' 목록에서 순서대로 하나씩 마쳐주세요.`
                  }
                />
                {(!isAllTaskDone || true) && <SidebarOpenButton size="lg" />}
              </Stack>
            </Box>
          ))}
      </Stack>

      {isCoach && patientState?.patientId && (
        <InsideC patientId={patientState.patientId} />
      )}
    </Sheet>
  ) : (
    <Sheet
      sx={{
        backgroundColor: "background.level2",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoEmptyPage />
    </Sheet>
  );
}
