import {
  Percentage,
  Select,
  SolidLabelLine,
  TypographyFromDataLine,
} from "data/CellComponent";
import {
  Instruction,
  InstructionWithButtonGroup,
  InstructionWithContent,
  InstructionWithDataWithTempContent,
  InstructionWithTextarea,
} from "../BlockComponent";
import { CoachActionBlock, LastCard } from "./common";

export const Content_104_2_A = (
  activityId: number,
  activityStepId: number,
  activityName: string
) => [
  InstructionWithDataWithTempContent(
    { A: { id: "activity" } },
    `typography_0_0_0`,
    [
      [
        {
          type: "temp",
          content: {
            id: `activity`,
            value: activityName,
          },
        },
      ],
    ]
  ),
  InstructionWithContent("typography_1_0_0", [
    ...[
      "예상 난이도",
      "누구와",
      "무엇을",
      "어디서",
      "언제",
      "예상 장애물",
      "장애물 극복법",
    ].flatMap((each) => [
      SolidLabelLine(`${each}`),
      each === "언제"
        ? TypographyFromDataLine(
            {
              A: {
                id: "date",
                taskId: `104-1-A#${activityId}#${activityStepId}`,
              },
              B: {
                id: "time",
                taskId: `104-1-A#${activityId}#${activityStepId}`,
              },
            },
            "$A$ $B$"
          )
        : TypographyFromDataLine(
            {
              A: {
                id: each,
                taskId: `104-1-A#${activityId}#${activityStepId}`,
              },
            },
            "$A$"
          ),
    ]),
  ]),

  InstructionWithButtonGroup(`typography_2_0_0`, [
    {
      translationKey: "네",
      showFromId: "성공",
      hideFromId: "실패",
    },
    {
      translationKey: "아니요",
      showFromId: "실패",
      hideFromId: "성공",
    },
  ]),

  Instruction(`typography_3_0_0`, {
    blockId: "성공",
  }),
  Instruction("typography_4_0_0"),
  InstructionWithTextarea(
    "success_typography_0_0_0",
    {},
    { blockId: "common_start" }
  ),
  InstructionWithTextarea("success_typography_1_0_0"),
  InstructionWithContent(`success_typography_2_0_0`, [
    [Percentage({ min: 0, max: 10, step: 1 })],
  ]),
  InstructionWithTextarea("success_typography_3_0_0"),
  InstructionWithTextarea(
    "success_typography_4_0_0",
    {},
    { blockId: "common_end" }
  ),

  Instruction(`typography_10_0_0`),
  Instruction(`typography_11_0_0`),
  Instruction(`typography_12_0_0`, { nextBlockId: "마무리" }),

  Instruction(`typography_13_0_0`, {
    blockId: "실패",
    isHidden: true,
  }),
  Instruction(`typography_14_0_0`, { isHidden: true }),
  Instruction(`typography_15_0_0`, { isHidden: true }),
  InstructionWithContent(
    `typography_16_0_0`,
    [
      [
        Select(
          [
            "까먹었어요",
            "어떻게 해야 할지 모르겠어요",
            "의지가 잘 안 생겼어요",
            "부정적인 생각이 들어서 못 했어요",
            "감정 조절이 어려웠어요",
            "몸이 긴장되어서 못 했어요",
            "과제 자체가 잘 이해되지 않았어요",
            "하긴 했는데 활동에 집중하지 못 했어요",
            "기타",
          ].map((each) => ({ text: each }))
        ),
      ],
    ],
    { isHidden: true }
  ),
  InstructionWithTextarea("typography_17_0_0", undefined, {
    isHidden: true,
  }),
  ...CoachActionBlock,

  Instruction(`typography_20_0_0`, { blockId: "마무리" }),
  ...LastCard,
];
