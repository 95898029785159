import TaskHeader from "components/task/header/TaskHeader";
import { Box } from "@mui/joy";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import PaymentModule from "components/sidebar/PaymentModule";
import MyValueCompassContent from "./content/MyValueCompassContent";

export default function MyValueCompass({
  showPaymentModule,
}: {
  showPaymentModule: boolean;
}) {
  const { isNotStarted } = useSubscriptionStatus();

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <TaskHeader taskKey={"valueCompass"} />
        {showPaymentModule && (
          <PaymentModule
            {...(isNotStarted && {
              title: "🧭 나에게 중요한 것은 무엇일까요?",
              subtitle: "내 삶의 가치를 찾아보세요.",
            })}
            type="header"
          />
        )}
      </Box>

      <div className="w-full h-full overflow-x-hidden">
        <MyValueCompassContent />
      </div>
    </>
  );
}
