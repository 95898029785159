import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import { Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { addAlpha } from "styles/hexOpacity";

export default function OptionalModal({
  open,
  setOpen,
  title,
  subtitle,
  textAlign,
  content,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  subtitle?: string;
  textAlign?: "center";
  content?: React.ReactNode;
}) {
  const [backdropOpacity, setBackdropOpacity] = useState(1);
  useEffect(() => {
    if (open) {
      setBackdropOpacity(1);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={(e, r) => {
          if (r === "backdropClick") {
            (e as MouseEvent).stopPropagation();
          }

          setBackdropOpacity(0);
          setOpen(false);
        }}
        sx={{
          "&>.MuiModal-backdrop": {
            backdropFilter: "none",
            backgroundColor: addAlpha("#000000", 0.3),
            opacity: backdropOpacity,
            transition: "all 0.3s",
          },
        }}
      >
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({ width: "100%", maxWidth: "500px" })}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          {title && (
            <Typography
              id="nested-modal-title"
              level="h2"
              sx={{ textAlign: textAlign }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography id="nested-modal-description" textColor="text.tertiary">
              {subtitle}
            </Typography>
          )}
          {content}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
