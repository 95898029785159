import { Box, Stack, Typography } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { useMediaQuery } from "@mui/material";
import { LogoWithTextSvg } from "static/image/common/SvgAssets";
import PatientList from "components/patient-list/PatientList";
import { isDev } from "config";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { addAlpha, extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import UserDropDown from "../user/UserDropDown";
import CxButton from "./CXButton";
import PaymentModule from "./PaymentModule";
import HomeTaskList from "components/task-list/HomeTaskList";
import MyPageModule from "./MyPageModule";

export type SidebarMenuType = "patient" | "task";

export default function Sidebar({ isHome }: { isHome?: boolean }) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const isInappWebview = useIsInappWebview();
  const { isCanceled, isExpired, isNotStarted } = useSubscriptionStatus();

  const [currentMenu, setCurrentMenu] = useState<SidebarMenuType>(
    !isCoach ? "task" : "patient"
  );

  const backgroundColor = isInappWebview
    ? "background.level1"
    : "background.level2";

  return (
    <Sheet>
      <Sheet
        className="Sidebar"
        sx={{
          position: !isHome && isInappWebview ? "fixed" : "sticky",

          transform:
            !isHome && isInappWebview ? "translateX(-100%)" : "translateX(0)",
          transition: "transform 0.4s, width 0.4s",
          width: "var(--Sidebar-width)",

          paddingBottom: `${20}px`,
          height: "100%",
          top: 0,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          backgroundColor: backgroundColor,
          overflow: "hidden",
        }}
      >
        <Stack
          sx={
            {
              // backgroundColor: "white",
              // borderBottomWidth: "1px",
              // borderColor: "divider",
            }
          }
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              px: "24px",
              height: "var(--StickyHeader-height)",
              minHeight: "var(--StickyHeader-height)",
            }}
          >
            <LogoWithTextSvg />
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: "200%",
            minHeight: 0,
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "var(--Sidebar-width)",
              px: "20px",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
            }}
          >
            {isCoach && (
              <PatientList
                setCurrentMenu={setCurrentMenu}
                backgroundColor={backgroundColor}
              />
            )}
          </Box>

          <Box
            sx={{
              width: "var(--Sidebar-width)",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
              transform:
                currentMenu === "task" ? "translateX(-100%)" : "translateX(0)",
              transition: "transform 0.4s, width 0.4s",
              backgroundColor: backgroundColor,
              zIndex: 10,
            }}
          >
            <HomeTaskList
              setCurrentMenu={setCurrentMenu}
              backgroundColor={backgroundColor}
            />
            {isInappWebview && (
              <Box sx={{}}>
                <MyPageModule />
              </Box>
            )}
          </Box>
        </Stack>
        {user?.role === "patient" &&
          (isCanceled || isExpired || isDev ? (
            <Box>
              <Box
                sx={{
                  px: "20px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    zIndex: 10,
                    left: 0,
                    width: "100%",
                    height: "12px",
                    background: `linear-gradient(180deg, ${addAlpha(
                      extractHexColor(theme.vars.palette.background.level2),
                      0
                    )} 0%, ${addAlpha(
                      extractHexColor(theme.vars.palette.background.level2),
                      1
                    )} 100%)`,
                  }}
                />
                <PaymentModule type="card" />
              </Box>
            </Box>
          ) : (
            <></>
          ))}

        {!isInappWebview && <MyPageModule />}
      </Sheet>
    </Sheet>
  );
}
