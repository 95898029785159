import {
  TypographyType,
  SingleSelectionType,
  TextareaType,
  TypographyFromDataType,
  ImageType,
  SelfCheckType,
  TypographyFromDashboardType,
  PercentageType,
  PieChartType,
  ElementType,
  ContentType,
  celltype,
  AudioType,
  ThoughtmapType,
  PresetType,
  ReferenceType,
  AiCommentType,
  ConceptItemType,
  MultipleSelectionType,
  PushNotificationType,
  ButtonLinkType,
  AITypographyType,
  IsolationType,
} from "data/CellComponent";
import TextareaCell from "components/task/content/cell/TextareaCell";
import SelectCell from "components/task/content/cell/SelectCell";
import TypographyFromDataCell from "components/task/content/cell/TypographyFromDataCell";
import * as _ from "lodash";
import ButtonGroupCell from "components/task/content/cell/ButtonGroupCell";
import { Box } from "@mui/joy";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import SelfCheckRadioButtonCell from "components/task/content/cell/SelfCheckRadioButtonCell";
import {
  getTaskIdFromTaskKey,
  getPercentageSum,
  getAutomaticThoughtId,
} from "logic/logics";
import { ProgramContentType } from "data/BlockComponent";
import React, { memo } from "react";
import TypographyFromDashboardCell from "./TypographyFromDashboardCell";
import PercentageCell from "./PercentageCell";
import PieChartCell from "./PieChartCell";
import DateSelectCell from "./DateSelectCell";
import AiText from "components/ai/AiText";
import ExtractFromThoughtRecordCell from "./ExtractFromThoughtRecordCell";
import TypographyFromConceptNoteCell from "./TypographyFromConceptNoteCell";
import SidebarOpenButton from "components/common/SidebarOpenButton";
import TypographyCell from "./TypographyCell";
import RequestPushNotificationButton from "components/common/RequestPushNotificationButton";
import LoginButtonByDevice from "components/user/LoginButtonByDevice";
import { useTranslation } from "react-i18next";
import AudioButtonCell from "./AudioButtonCell";
import DateHeaderCell from "./DateHeaderCell";
import ThoughtmapCell from "./ThoughtmapCell";
import RequestStoreReviewButton from "components/common/RequestStoreReviewButton";
import useGetLocale from "hooks/useGetLocale";
import GotoPaymentButton from "components/common/GoToPaymentButton";
import PresetCell from "./PresetCell";
import ReferenceCell from "./ReferenceCell";
import SelectIntermediateBeliefCell from "./SelectIntermediateBeliefCell";
import AiCommentCell from "./AiCommentCell";
import ConceptItemCell from "./ConceptItemCell";
import FeedbackCell from "./FeedbackCell";
import { automaticThoughtIdType } from "api/conceptApi";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { currentTaskSectionIndexAtom } from "recoil/currentTaskSectionIndexAtom";
import ButtonGroupMultipleChoiceInput from "./ButtonGroupMultipleChoiceInput";
import TimeSelectCell from "./TimeSelectCell";
import AddActivityPlanCell from "./AddActivityPlanCell";
import ButtonLink from "components/common/ButtonLink";
import CellContainer from "./CellContainer";
import ImageCell from "./ImageCell";
import IsolationCell from "./IsolationCell";
import AITypographyCell from "./AITypographyCell";
import { isTaskIdValid } from "data/programData";
import ValueCompass from "components/value/ValueCompass";
import useIsInappWebview from "hooks/useIsInappWebview";
import AudioButtonCellWebview from "./AudioButtonCellWebview";
import useDetectIOS from "hooks/useDetectIOS";
import ShareButton from "./ShareButton";

function Cell({
  type,
  contentStr,
  blockIndex,
  lineIndex,
  cellIndex,
  taskKey,
  thoughtRecordKey,
  meditationRecordKey,
  activityRecordKey,
  data,
  setData,
  translationVersion,
  readonly,
  isShown,
}: {
  type: celltype;
  contentStr: string;
  blockIndex: number;
  lineIndex: number;
  cellIndex: number;
  taskKey: string;
  thoughtRecordKey?: string;
  meditationRecordKey?: string;
  activityRecordKey?: string;
  data?: ProgramContentType[];
  setData: React.Dispatch<
    React.SetStateAction<ProgramContentType[] | undefined>
  >;
  translationVersion?: string;
  readonly: boolean;
  isShown: boolean;
}) {
  const content: ContentType = JSON.parse(contentStr);
  // const translationKey_raw = (content as TypographyType).translationKey;
  // const translationKey =
  //   translationKey_raw?.substring(translationKey_raw.length - 1) === "."
  //     ? translationKey_raw.substring(0, translationKey_raw.length - 1)
  //     : translationKey_raw;
  const translationKey = (content as TypographyType).translationKey;
  const user = useRecoilValue(userAtom);
  const isCoach = (user?.accessToken && user?.role !== "patient") || false;

  const taskSectionState = useRecoilValue(taskSectionAtom);
  const currentTaskSectionIndex = useRecoilValue(currentTaskSectionIndexAtom);
  // const { currentTaskKey } = (taskSectionState || [])[currentTaskSectionIndex];

  const isInappWebview = useIsInappWebview();
  const { isIOS } = useDetectIOS();

  const locale = useGetLocale();

  const { t } = useTranslation("translation", {
    keyPrefix: `task.activity.${getTaskIdFromTaskKey(
      taskKey
    )}.${translationVersion}`,
  });

  const { t: t_thoughtRecord_v1 } = useTranslation("translation", {
    keyPrefix: `task.activity.2-2-T.v1`,
  });
  const { t: t_thoughtRecord_v3 } = useTranslation("translation", {
    keyPrefix: `task.activity.2-2-T.v3`,
  });
  const { t: t_activityRecord } = useTranslation("translation", {
    keyPrefix: `task.activity.101-1-3-T.v1`,
  });
  const { t: t_common } = useTranslation("translation", {
    keyPrefix: "task.activity.common",
  });

  const taskId_fromOtherTask = translationKey?.split(":")[0];
  const translationVersion_fromOtherTask = translationKey?.split(":")[1];
  const translationKey_fromOtherTask =
    translationKey?.split(":")[2] || translationVersion_fromOtherTask;

  const { t: t_fromOtherTask } = useTranslation("translation", {
    keyPrefix: `task.activity.${taskId_fromOtherTask}.${
      translationVersion_fromOtherTask?.startsWith("v")
        ? translationVersion_fromOtherTask
        : "v1"
    }`,
  });

  const translatedText_noCommon = isTaskIdValid(taskId_fromOtherTask || "")
    ? t_fromOtherTask(translationKey_fromOtherTask || "")
    : t(translationKey || "");

  // const translatedText = !translationVersion
  //   ? translationKey || ""
  //   : translationKey?.startsWith("common")
  //   ? t_common(translationKey?.split(":")[1] || "")
  //   : translationKey === "$A$"
  //   ? t_common(translationKey || "")
  //   : (content as TypographyType)?.isLabel
  //   ? t_common(translationKey || "")
  //   : translationKey?.includes("2-2-T:")//자사살애서 불러온 블록
  //   ? translationKey?.split(":")[1] === "v3"
  //     ? t_thoughtRecord_v3(translationKey?.split(":")[2] || "")
  //     : t_thoughtRecord_v1(
  //         translationKey?.split(":")[2] || translationKey?.split(":")[1] || ""
  //       )
  //   : t(translationKey || "");
  const translatedText = !translationVersion
    ? translationKey || ""
    : translationKey?.startsWith("common")
    ? t_common(translationKey?.split(":")[1] || "")
    : translationKey === "$A$"
    ? t_common(translationKey || "")
    : (content as TypographyType)?.isLabel
    ? // ? t_common(translationKey || "")
      translationKey || ""
    : translatedText_noCommon;

  const translatedOptions =
    (content as SingleSelectionType)["options"]?.map(
      (each) =>
        each.text ||
        (each.translationKey?.startsWith("common")
          ? t_common(each.translationKey?.split(":")[1] || "")
          : t(each.translationKey || ""))
    ) || [];

  const translationKeys =
    (content as SingleSelectionType)["options"]
      ?.filter((element) => t(element.translationKey || ""))
      .map((each) => each.translationKey || "") || [];

  const isCoachField = content.coach;
  return (
    <CellContainer
      readOnly={
        (isCoachField && !isCoach) ||
        (type === "buttongroup" && !isCoachField && isCoach)
      }
      isCoachFieldEmpty={
        isCoach &&
        !content.optional &&
        isCoachField &&
        (content.value || "").trim().length === 0
      }
    >
      {type === "typography" ? (
        <TypographyCell
          {...content}
          // text={
          //   (content as TypographyType)?.text || (content as TypographyType)?.value
          //     ? (content as TypographyType)?.text ||
          //       (content as TypographyType)?.value
          //     : (content as TypographyType)?.translationKey
          //     ? translatedText
          //     : ""
          // }
          text={
            (content as TypographyType)?.translationKey
              ? translatedText
              : (content as TypographyType)?.text ||
                (content as TypographyType)?.value ||
                ""
          }
        />
      ) : type === "dateHeader" ? (
        <DateHeaderCell {...content} />
      ) : type === "image" ? (
        <ImageCell taskKey={taskKey} url={(content as ImageType).imageUrl} />
      ) : type === "select" ? (
        <SelectCell
          defaultValue={(content as SingleSelectionType).value}
          selectedIndex={(content as SingleSelectionType).selectedIndex}
          blockIndex={blockIndex}
          options={translatedOptions}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          isCoachField={(content as ElementType).coach}
          isCoach={isCoach}
        />
      ) : type === "buttongroup" ? (
        <ButtonGroupCell
          defaultValue={(content as SingleSelectionType).value}
          selectedIndex={(content as SingleSelectionType).selectedIndex}
          blockIndex={blockIndex}
          options={translatedOptions}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          isCoach={isCoach}
        />
      ) : type === "buttongroup_multipleChoice" ? (
        <ButtonGroupMultipleChoiceInput
          initialSelectedIndices={
            (content as MultipleSelectionType).selectedIndices
          }
          requiredSelectionCount={
            (content as MultipleSelectionType).requiredSelectionCount
          }
          blockIndex={blockIndex}
          options={translatedOptions}
          {...(translationKeys.every((element) => element.length > 0) && {
            optionKeys: translationKeys,
          })}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          isCoach={isCoach}
        />
      ) : type === "textarea" ? (
        <TextareaCell
          // readOnly={(content as TextareaType).coach && !isCoach}
          placeholder={(content as TextareaType).placeholder}
          initialHtmlString={(content as TextareaType).htmlString}
          initialValue={(content as TextareaType).value}
          isCoachField={(content as TextareaType).coach}
          isCoach={isCoach}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
          id={(content as TextareaType)?.id}
        />
      ) : type === "preset" ? (
        <PresetCell
          taskKey={taskKey || ""}
          readOnly={(content as TextareaType).coach && !isCoach}
          placeholder={(content as TextareaType).placeholder}
          initialHtmlString={(content as TextareaType).htmlString}
          initialValue={(content as TextareaType).value || undefined}
          initialSelectedIndex={(content as SingleSelectionType).selectedIndex}
          isCoachField={(content as TextareaType).coach}
          isCoach={isCoach}
          setData={setData}
          options={translatedOptions}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
          presetKey={(content as PresetType)?.presetKey}
        />
      ) : type === "add_activity_plan" ? (
        <AddActivityPlanCell
          taskKey={taskKey || ""}
          ark={activityRecordKey}
          placeholder={(content as TextareaType).placeholder}
          initialValue={(content as TextareaType).value || undefined}
          isCoach={isCoach}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
        />
      ) : type === "reference" ? (
        <ReferenceCell
          taskKey={taskKey || ""}
          placeholder={(content as TextareaType).placeholder}
          initialHtmlString={(content as TextareaType).htmlString}
          initialValue={(content as TextareaType).value}
          isCoach={isCoach}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
          referenceKey={(content as ReferenceType)?.referenceKey}
        />
      ) : type === "aiComment" ? (
        <AiCommentCell
          taskKey={taskKey || ""}
          thoughtRecordKey={thoughtRecordKey}
          initialValue={(content as AiCommentType).value}
          aiCommentValue={(content as AiCommentType).aiCommentValue}
          isCoach={isCoach}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as AiCommentType)?.editorKey}
          aiCommentKey={(content as AiCommentType)?.aiCommentKey}
        />
      ) : type === "typographyFromDashboard" ? (
        <TypographyFromDashboardCell
          taskKey={taskKey}
          initialValue={content.value}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          id1={(content as TypographyFromDashboardType).id1}
          id2={(content as TypographyFromDashboardType).id2}
          level={(content as TypographyFromDashboardType).level}
        />
      ) : type === "typographyFromConceptNote" ? (
        <TypographyFromConceptNoteCell
          taskKey={taskKey || ""}
          initialHtmlString={(content as TextareaType).htmlString}
          initialValue={(content as TextareaType).value}
          isCoach={isCoach}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
          id1={(content as TypographyFromDashboardType).id1}
          id2={(content as TypographyFromDashboardType).id2}
        />
      ) : type === "conceptItem" ? (
        <ConceptItemCell
          taskKey={taskKey || ""}
          conceptKey={(content as ConceptItemType).conceptKey}
          isNotConfirmed={(content as ConceptItemType).isNotConfirmed}
        />
      ) : type === "selfCheck" ? (
        <SelfCheckRadioButtonCell
          defaultValue={(content as SelfCheckType).selectedScore}
          blockIndex={blockIndex}
          evaluation={Object.fromEntries(
            Object.entries((content as SelfCheckType).evaluation).map(
              ([key, value]) => [t_common(key), value]
            )
          )}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
        />
      ) : type === "extractFromThoughtRecord" ? (
        <ExtractFromThoughtRecordCell
          automaticThoughtId={
            getAutomaticThoughtId(
              getTaskIdFromTaskKey(taskKey)
            ) as automaticThoughtIdType
          }
          isPositive={taskKey.startsWith("7-4-A")}
          defaultValue={content.value}
          blockIndex={blockIndex}
          data={data || []}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
        />
      ) : type === "login" ? (
        <LoginButtonByDevice />
      ) : type === "payment" ? (
        <GotoPaymentButton disabled={readonly} />
      ) : type === "pushNotification" ? (
        <RequestPushNotificationButton
          setData={setData}
          blockIndex={blockIndex}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          showFromIdIfDenied={
            (content as PushNotificationType).showFromIdIfDenied
          }
          disabled={readonly}
          clicked={(content as PushNotificationType).clicked}
        />
      ) : type === "storeReview" ? (
        <RequestStoreReviewButton disabled={readonly} />
      ) : type === "share" ? (
        <ShareButton isShown={isShown} />
      ) : type === "buttonLink" ? (
        <ButtonLink disabled={readonly} {...(content as ButtonLinkType)} />
      ) : type === "sidebarOpenButton" ? (
        <SidebarOpenButton disabled={readonly} />
      ) : type === "audio" ? (
        isInappWebview && !isIOS ? (
          <AudioButtonCellWebview
            isCurrentTask
            taskKey={taskKey}
            meditationRecordKey={meditationRecordKey}
            url={`https://media.distancing.im/audio/${
              (content as AudioType).audioUrl
            }_${locale}.mp3`}
            initialTime={Math.floor((content as AudioType).currentTime || 0)}
            setData={setData}
            blockIndex={blockIndex}
            lineIndex={lineIndex}
            cellIndex={cellIndex}
            isCoach={isCoach}
            disabled={readonly}
            {...((content as AudioType).extraAudioUrl && {
              extraAudioUrl: `https://media.distancing.im/audio/${
                (content as AudioType).extraAudioUrl
              }_${locale}.mp3`,
            })}
            extraLoopNumber={(content as AudioType).extraLoopNumber}
          />
        ) : (
          <AudioButtonCell
            isCurrentTask
            // url={require(`../../../../static/audio/${
            //   (content as AudioType).audioUrl
            // }_${locale}.mp3`)}
            url={`https://media.distancing.im/audio/${
              (content as AudioType).audioUrl
            }_${locale}.mp3`}
            initialTime={Math.floor((content as AudioType).currentTime || 0)}
            setData={setData}
            blockIndex={blockIndex}
            lineIndex={lineIndex}
            cellIndex={cellIndex}
            isCoach={isCoach}
            disabled={readonly}
            {...((content as AudioType).extraAudioUrl && {
              // extraAudioUrl: require(`../../../../static/audio/${
              //   (content as AudioType).extraAudioUrl
              // }_${locale}.mp3`),
              extraAudioUrl: `https://media.distancing.im/audio/${
                (content as AudioType).extraAudioUrl
              }_${locale}.mp3`,
            })}
            extraLoopNumber={(content as AudioType).extraLoopNumber}
          />
        )
      ) : //  : type === "divider" ? (
      //   <Box
      //     sx={{
      //       height: "1px",
      //       width: "100%",
      //       backgroundColor: "divider",
      //     }}
      //   />
      // )
      type === "date" ? (
        <DateSelectCell
          editorKey={(content as ElementType)?.editorKey}
          defaultValue={(content as ElementType).value}
          blockIndex={blockIndex}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
        />
      ) : type === "time" ? (
        <TimeSelectCell
          editorKey={(content as ElementType)?.editorKey}
          defaultValue={(content as ElementType).value}
          blockIndex={blockIndex}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
        />
      ) : type === "percentage" ? (
        <PercentageCell
          id={(content as PercentageType).id}
          initialPercentage={
            content?.complementPercentageIdList
              ? Math.max(
                  0,
                  100 -
                    getPercentageSum(
                      data || [],
                      content?.complementPercentageIdList
                    )
                )
              : (content as PercentageType).percentage
          }
          min={(content as PercentageType).min}
          max={(content as PercentageType).max}
          step={(content as PercentageType).step}
          inputKey={`${(content as PercentageType).editorKey}`}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
        />
      ) : type === "typographyFromData" ? (
        <TypographyFromDataCell
          taskKey={taskKey}
          {...content}
          data={data || []}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          dataMapping={(content as TypographyFromDataType).dataMapping || {}}
          initialValue={(content as TypographyFromDataType).value}
          translationVersion={translationVersion}
          text={
            (content as TypographyType)?.translationKey
              ? translatedText
              : (content as TypographyType)?.text || ""
          }
        />
      ) : type === "pieChart" ? (
        <PieChartCell
          data={data || []}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          pieChartId={(content as PieChartType).pieChartId}
        />
      ) : type === "thoughtmap" ? (
        <ThoughtmapCell
          from={(content as ThoughtmapType).from}
          to={(content as ThoughtmapType).to}
          fixedValue={(content as ThoughtmapType).fixedValue}
        />
      ) : type === "selectIntermediateBelief" ? (
        <SelectIntermediateBeliefCell
          selectedIndex={(content as SingleSelectionType).selectedIndex}
          blockIndex={blockIndex}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
        />
      ) : type === "valueCompass" ? (
        <ValueCompass excludePatterns />
      ) : type === "feedback" ? (
        <FeedbackCell
          taskKey={taskKey || ""}
          savedFeedback={(content as TextareaType).value}
          setData={setData}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          blockIndex={blockIndex}
          editorKey={(content as TextareaType)?.editorKey}
        />
      ) : type === "isolation" ? (
        <IsolationCell
          thoughtRecordKey={thoughtRecordKey || ""}
          isShown={isShown}
          setData={setData}
          blockIndex={blockIndex}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          isolatedData={(content as IsolationType).isolatedData}
        />
      ) : type === "aiTypography" ? (
        <AITypographyCell
          thoughtRecordKey={thoughtRecordKey || ""}
          isShown={isShown}
          messageId={(content as AITypographyType)?.messageId}
          setData={setData}
          blockIndex={blockIndex}
          lineIndex={lineIndex}
          cellIndex={cellIndex}
          value={content.value}
        />
      ) : (
        <div></div>
      )}
    </CellContainer>
  );
}

export default memo(Cell);
