import { automaticThoughtIdType } from "api/conceptApi";
import {
  DimmedLabelLine,
  DividerLine,
  LineWithSolidLabel,
  SolidLabelLine,
  TextareaLine,
  TypographyFromDataLine,
  TypographyLine,
} from "data/CellComponent";
import {
  Header1,
  Instruction,
  InstructionWithContent,
  InstructionWithDataWithContent,
  InstructionWithDataWithTextarea,
  InstructionWithTextarea,
} from "../BlockComponent";
import {
  Content_4_5_A_end,
  Content_4_5_A_end_type2,
  Content_4_5_A_start,
} from "./4-5-A";
import { AutomaticThought } from "./common";

export const Content_4_5_4_A = (automaticThoughtId: automaticThoughtIdType) => [
  ...Content_4_5_A_start(automaticThoughtId),
  Header1(`typography_0_0_0`),

  InstructionWithContent(
    `typography_1_0_0`,
    [`typography_1_3_1`, `typography_1_5_0`, `typography_1_7_0`].flatMap(
      (each, index) => [
        ...LineWithSolidLabel(`단계`, TypographyLine(each), {
          labelIndex: index + 1,
        }),
      ]
    )
  ),
  InstructionWithContent(
    `typography_2_0_0`,
    AutomaticThought({ id: automaticThoughtId })
  ),
  InstructionWithContent(`typography_3_1_0`, [
    ...LineWithSolidLabel(`사실 (상황과 나의 직접적인 특성)`, TextareaLine()),
    ...LineWithSolidLabel(
      `평가 (상황과 나에 대한 가치 판단)`,
      TextareaLine({ id: "평가" })
    ),
  ]),
  InstructionWithTextarea(`typography_4_0_0`),
  Instruction(`typography_5_0_0`),
  InstructionWithDataWithTextarea(
    { A: { id: "평가" } },
    `typographyFromData_6_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "평가" } },
    `typographyFromData_7_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "평가" } },
    `typographyFromData_8_0_0`
  ),
  InstructionWithDataWithTextarea(
    { A: { id: "평가" } },
    `typographyFromData_9_0_0`
  ),

  InstructionWithDataWithContent(
    { A: { id: "평가" } },
    `typographyFromData_10_0_0`,
    [
      ...[
        [`typography_10_4_0`, `typography_10_6_0`],
        [`typography_10_10_0`, `typography_10_12_0`],
      ].flatMap((each, index) => [
        SolidLabelLine(`예시`, { labelIndex: index + 1 }),
        DimmedLabelLine(`마음속에 떠오른 평가`),
        TypographyLine(each[0]),
        DimmedLabelLine(`거리를 두고 바라본 평가`),
        TypographyLine(each[1]),
      ]),
      SolidLabelLine(`나의 평가`),
      DimmedLabelLine(`마음속에 떠오른 평가`),
      TypographyFromDataLine({ A: { id: "평가" } }, "$A$"),
      DimmedLabelLine(`거리를 두고 바라본 평가`),
      TextareaLine(),
    ]
  ),

  Instruction(`typography_11_0_0`),

  // ...Content_4_5_A_end_type2(automaticThoughtId, 4),
  ...Content_4_5_A_end(automaticThoughtId, 4),
];
