import { Button, Stack } from "@mui/joy";

export default function RecordListTabs({
  selectedTab,
  data,
}: {
  selectedTab?: string;
  data: { id: string; label: string; onClick: () => void }[];
}) {
  return (
    <Stack
      direction="row"
      spacing="8px"
      sx={{ width: "100%", height: "fit-content" }}
    >
      {data.map(({ id, label, onClick }) => (
        <Stack
          sx={{
            flex: 1,
          }}
        >
          <Button
            variant="outlined"
            size="sm"
            color={id === selectedTab ? "primary" : "secondary"}
            onClick={onClick}
          >
            {label}
          </Button>
        </Stack>
      ))}
    </Stack>
  );
}
