import { Stack } from "@mui/joy";
import useIsInappWebview from "hooks/useIsInappWebview";
import OverviewItem from "./OverviewItem";

export default function Overview() {
  const isInappWebview = useIsInappWebview();
  return (
    <Stack
      direction={isInappWebview ? "row" : "column"}
      justifyContent="center"
      spacing="8px"
      sx={{
        width: "100%",
      }}
    >
      {["progress", "thoughtmap", "valueCompass"].map((each, index) => (
        <Stack sx={{ flex: 1 }}>
          <OverviewItem taskKey={each} />
        </Stack>
      ))}
    </Stack>
  );
}
