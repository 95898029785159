import { TypographySystem } from "@mui/joy";
import { PresetKeyType } from "api";
import {
  AutomaticThoughtForValueKeyType,
  AutomaticThoughtKeyType,
  ConceptKeyType,
  CopingStrategyKeyType,
  CoreBeliefKeyType,
  IntermediateBeliefKeyType,
  ReferenceKeyType,
} from "api/conceptApi";
import { EvaluationType } from "./selfCheckData";

export type levelType = keyof TypographySystem;

export type celltype =
  | "typography" //translation
  | "image"
  | "textarea"
  | "select" //translation
  | "buttongroup" //translation
  | "buttongroup_multipleChoice" //translation
  | "typographyFromData"
  | "typographyFromDashboard"
  | "typographyFromConceptNote"
  | "selfCheck"
  | "percentage"
  | "pieChart"
  | "temp"
  | "divider"
  | "date"
  | "time"
  | "dateHeader"
  | "extractFromThoughtRecord"
  | "sidebarOpenButton"
  | "login"
  | "payment"
  | "pushNotification"
  | "audio"
  | "storeReview"
  | "thoughtmap"
  | "valueCompass"
  | "preset"
  | "reference"
  | "selectIntermediateBelief"
  | "aiComment"
  | "conceptItem"
  | "feedback"
  | "add_activity_plan"
  | "buttonLink"
  | "isolation"
  | "aiTypography"
  | "share";

export interface ElementType {
  width?: number;
  opacity?: number;
  level?: levelType;

  id?: string;
  value?: string;
  optional?: boolean;
  pieChartDataId?: string;
  requiredId?: string;
  percentageIdList?: string[];
  complementPercentageIdList?: string[]; // 100에서 뺄거
  answerList?: { id: string; answerIndex?: number; value?: string }[];
  editorKey?: string;

  coach?: boolean;
  readonly?: boolean;
  translationKey?: string;

  queryKey?: string[];
  isHidden?: boolean;
}

export interface TypographyType extends ElementType {
  text?: string;
  variant?: "soft";
  italic?: boolean;
  color?: string;
  labelIndex?: number;
  prefixLabelIndex?: number;
  isLabel?: boolean;
}

export interface OptionType {
  dataMapping?: DataMappingType;
  translationKey?: string;
  translationKey_additional?: string;
  text?: string;
  hideFromId?: string;
  showFromId?: string;
  blockIdForBranch?: string;
  hideBlockIdList?: string[];
  showBlockIdList?: string[];
  subjectiveOption?: { optional?: boolean; id?: string; placeholder?: string };
  additionalContent?: CellType[][];
}

export interface TextareaType extends ElementType {
  placeholder?: string;
  htmlString?: string;
}

export interface PresetType extends TextareaType, SingleSelectionType {
  presetKey?: PresetKeyType;
}
export interface ReferenceType extends ElementType {
  referenceKey?: ReferenceKeyType;
}

export interface AiCommentType extends ElementType {
  aiCommentKey: string;
  aiCommentValue: string;
}

export interface PercentageType extends TextareaType {
  percentage?: number;
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number;
}

export interface SingleSelectionType extends ElementType {
  options?: OptionType[];
  selectedIndex?: number;
  // selectedTranslationKey?: string;
  // selectedTranslationKey_additional?: string;
}

export interface MultipleSelectionType extends ElementType {
  options?: OptionType[];
  selectedIndices?: number[];
  requiredSelectionCount?: number;
}

export interface SelectIntermediateBeliefType extends ElementType {
  selectedIndex?: number;
}

export interface DataMappingType {
  [key: string]: {
    taskId?: string;
    id?: string;
    index?: number;
    additional?: boolean;
    value?: string;
  };
}

export interface TypographyFromDataType extends TypographyType {
  dataMapping?: DataMappingType;
}

export interface TypographyFromDashboardType extends ElementType {
  id1: ConceptKeyType;
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType;
}

export interface ImageType extends ElementType {
  imageUrl: string;
}

export interface AudioType extends ElementType {
  audioUrl: string;
  isEnded?: boolean;
  currentTime?: number;
  extraAudioUrl?: string;
  extraLoopNumber?: number;
}
export interface SelfCheckType extends ElementType {
  selfCheckId: string;
  evaluation: EvaluationType;
  selectedScore?: number;
}

export interface PieChartType extends ElementType {
  pieChartId: string;
  data?: { id: string; label: string; value: number }[];
}

export interface ThoughtmapType extends ElementType {
  from?: ConceptKeyType;
  to?: ConceptKeyType;
  fixedValue?: { [key in ConceptKeyType]?: string };
}

export interface ConceptItemType extends ElementType {
  conceptKey: ConceptKeyType;
  isNotConfirmed?: boolean;
}

export interface PushNotificationType extends ElementType {
  showFromIdIfDenied?: string;
  clicked?: boolean;
}
export interface ButtonLinkType extends ElementType {
  text: string;
  url: string;
}

export interface AITypographyType extends ElementType {
  messageId?: string;
}

export interface IsolationDataType {
  상황?: string;
  생각?: string;
  감정?: string;
  신체?: string;
  행동?: string;
}
export interface IsolationType extends ElementType {
  isolatedData?: IsolationDataType;
}

export type ContentType =
  | TypographyType
  | TextareaType
  | SingleSelectionType
  | MultipleSelectionType
  | ImageType
  | AudioType
  | TypographyFromDataType
  | TypographyFromDashboardType
  | SelfCheckType
  | PercentageType
  | PieChartType
  | ThoughtmapType
  | PresetType
  | ReferenceType
  | SelectIntermediateBeliefType
  | AiCommentType
  | ConceptItemType
  | PushNotificationType
  | ButtonLinkType
  | AITypographyType
  | ElementType;

export interface CellType {
  type: celltype;
  content: ContentType;
}

export function DateSelect(arg?: ElementType): {
  type: "date";
  content: ElementType;
} {
  return {
    type: "date",
    content: {
      ...(arg && arg),
    },
  };
}
export function TimeSelect(arg?: ElementType): {
  type: "time";
  content: ElementType;
} {
  return {
    type: "time",
    content: {
      ...(arg && arg),
    },
  };
}
export function Date(value: string): {
  type: "dateHeader";
  content: ElementType;
} {
  return {
    type: "dateHeader",
    content: {
      value: value,
    },
  };
}

export function Typography(
  translationKey: string,
  arg?: TypographyType
): { type: "typography"; content: TypographyType } {
  return {
    type: "typography",
    content: {
      translationKey: translationKey,
      ...(arg && arg),
    },
  };
}
export function AITypographyLine(
  messageId?: string
): { type: "aiTypography"; content: AITypographyType }[] {
  return [
    {
      type: "aiTypography",
      content: {
        messageId: messageId,
      },
    },
  ];
}

export function Isolation(): { type: "isolation"; content: IsolationType }[] {
  return [
    {
      type: "isolation",
      content: {},
    },
  ];
}

export function TypographyFromData(
  dataMapping: DataMappingType,
  translationKey: string,
  arg?: TypographyFromDataType
): { type: "typographyFromData"; content: TypographyFromDataType } {
  return {
    type: "typographyFromData",
    content: {
      dataMapping: dataMapping,
      translationKey: translationKey,
      ...(arg && arg),
    },
  };
}

export function Percentage(arg?: PercentageType): {
  type: "percentage";
  content: PercentageType;
} {
  return {
    type: "percentage",
    content: { ...(arg && arg) },
  };
}

export function Textarea(arg?: TextareaType): {
  type: "textarea";
  content: TextareaType;
} {
  return {
    type: "textarea",
    content: { ...(arg && arg) },
  };
}

export function CoachTextarea(arg?: TextareaType): {
  type: "textarea";
  content: TextareaType;
} {
  return {
    type: "textarea",
    content: {
      coach: true,
      ...(arg && arg),
    },
  };
}

export function Preset(arg?: PresetType): {
  type: "preset";
  content: PresetType;
} {
  return {
    type: "preset",
    content: {
      coach: true,
      ...(arg && arg),
    },
  };
}

export function Reference(arg?: ReferenceType): {
  type: "reference";
  content: ReferenceType;
} {
  return {
    type: "reference",
    content: {
      coach: true,

      ...(arg && arg),
    },
  };
}
export function AiComment(
  aiCommentKey: string,
  aiCommentValue: string,
  arg?: ElementType
): {
  type: "aiComment";
  content: AiCommentType;
} {
  return {
    type: "aiComment",
    content: {
      coach: true,
      aiCommentKey: aiCommentKey,
      aiCommentValue: aiCommentValue,
      ...(arg && arg),
    },
  };
}

export function SelectIntermediateBelief(arg?: ElementType): {
  type: "selectIntermediateBelief";
  content: ContentType;
} {
  return {
    type: "selectIntermediateBelief",
    content: {
      ...(arg && arg),
    },
  };
}

export function Select(
  options: OptionType[],
  arg?: SingleSelectionType
): { type: "select"; content: SingleSelectionType } {
  return {
    type: "select",
    content: {
      options: options,

      ...(arg && arg),
    },
  };
}

export function ButtonGroup(
  options: OptionType[],
  arg?: SingleSelectionType
  // width?: number
): { type: "buttongroup"; content: SingleSelectionType } {
  return {
    type: "buttongroup",
    content: {
      options: options,
      ...(arg && arg),
    },
  };
}

export function ButtonGroupMultipleChoice(
  options: OptionType[],
  arg?: MultipleSelectionType
): { type: "buttongroup_multipleChoice"; content: MultipleSelectionType } {
  return {
    type: "buttongroup_multipleChoice",
    content: {
      options: options,
      ...(arg && arg),
    },
  };
}

export function Image(url: string): { type: "image"; content: ImageType }[] {
  return [
    {
      type: "image",
      content: { imageUrl: url },
    },
  ];
}

export function Audio(
  url: string,
  extraAudioUrl?: string
): { type: "audio"; content: AudioType }[] {
  return [
    {
      type: "audio",
      content: { audioUrl: url, extraAudioUrl: extraAudioUrl },
    },
  ];
}
export function PieChart(
  id: string
): { type: "pieChart"; content: PieChartType }[] {
  return [
    {
      type: "pieChart",
      content: { pieChartId: id },
    },
  ];
}

export function Thoughtmap(
  from?: ConceptKeyType,
  to?: ConceptKeyType,
  fixedValue?: { [key in ConceptKeyType]?: string }
): { type: "thoughtmap"; content: ThoughtmapType }[] {
  return [
    {
      type: "thoughtmap",
      content: { from: from, to: to, fixedValue: fixedValue },
    },
  ];
}

export function ValueCompass(): {
  type: "valueCompass";
  content: ContentType;
}[] {
  return [
    {
      type: "valueCompass",
      content: {},
    },
  ];
}

export function ConceptNote(
  conceptKey: ConceptKeyType,
  isNotConfirmed?: boolean
): { type: "conceptItem"; content: ConceptItemType }[] {
  return [
    {
      type: "conceptItem",
      content: { conceptKey: conceptKey, isNotConfirmed: isNotConfirmed },
    },
  ];
}

export function DividerLine(
  arg?: TypographyType
): { type: "divider"; content: ElementType }[] {
  return [
    {
      type: "divider",
      content: { ...(arg && arg) },
    },
  ];
}
export function FeedbackLine(
  arg?: ElementType
): { type: "feedback"; content: ElementType }[] {
  return [
    {
      type: "feedback",
      content: { ...(arg && arg) },
    },
  ];
}
export function AddActivityLine(
  arg?: ElementType
): { type: "add_activity_plan"; content: ElementType }[] {
  return [
    {
      type: "add_activity_plan",
      content: { coach: true, optional: true, ...(arg && arg) },
    },
  ];
}
export function TypographyLine(
  text: string,
  arg?: TypographyType
): { type: "typography"; content: TypographyType }[] {
  return [Typography(text, arg)];
}
export function TypographyLineWithValue(
  text: string,
  arg?: TypographyType
): { type: "typography"; content: TypographyType }[] {
  return [
    {
      type: "typography",
      content: {
        value: text,
        ...(arg && arg),
      },
    },
  ];
}

export function GuideTypographyLine(
  text: string,
  arg?: TypographyType
): { type: "typography"; content: TypographyType }[] {
  return TypographyLine(text, {
    color: "danger.solidBg",
    level: "body-sm",
    ...arg,
  });
}

export function TypographyFromDataLine(
  dataMapping: DataMappingType,
  translationKey: string,
  arg?: TypographyFromDataType
): { type: "typographyFromData"; content: TypographyFromDataType }[] {
  return [TypographyFromData(dataMapping, translationKey, arg)];
}

export function TypographyFromConceptNote(
  id1: ConceptKeyType,
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType,
  arg?: ElementType
): {
  type: "typographyFromConceptNote";
  content: TypographyFromDashboardType;
} {
  return {
    type: "typographyFromConceptNote",
    content: {
      coach: true,

      id1: id1,
      id2: id2,
      ...(arg && arg),
    },
  };
}
export function TypographyFromConceptNoteLine(
  id1: ConceptKeyType,
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType,
  arg?: ElementType
): {
  type: "typographyFromConceptNote";
  content: TypographyFromDashboardType;
}[] {
  return [TypographyFromConceptNote(id1, id2, arg)];
}

export function TypographyFromDashboard(
  id1: ConceptKeyType,
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType,
  arg?: ElementType
): { type: "typographyFromDashboard"; content: TypographyFromDashboardType } {
  return {
    type: "typographyFromDashboard",
    content: {
      id1: id1,
      id2: id2,
      ...(arg && arg),
    },
  };
}

export function TypographyFromDashboardLine(
  id1: ConceptKeyType,
  id2?:
    | AutomaticThoughtKeyType
    | AutomaticThoughtForValueKeyType
    | CopingStrategyKeyType
    | IntermediateBeliefKeyType
    | CoreBeliefKeyType,
  arg?: ElementType
): { type: "typographyFromDashboard"; content: TypographyFromDashboardType }[] {
  return [TypographyFromDashboard(id1, id2, arg)];
}

export function TextareaLine(arg?: TextareaType): {
  type: "textarea";
  content: TextareaType;
}[] {
  return [Textarea(arg)];
}

export function CoachTextareaLine(arg?: TextareaType): {
  type: "textarea";
  content: TextareaType;
}[] {
  return [CoachTextarea(arg)];
}

export function PresetLine(arg?: PresetType): {
  type: "preset";
  content: PresetType;
}[] {
  return [Preset(arg)];
}
export function ReferenceLine(arg?: ReferenceType): {
  type: "reference";
  content: ReferenceType;
}[] {
  return [Reference(arg)];
}
export function AiCommentLine(
  aiCommentKey: string,
  aiCommentValue: string,
  arg?: ElementType
): {
  type: "aiComment";
  content: AiCommentType;
}[] {
  return [AiComment(aiCommentKey, aiCommentValue, arg)];
}

export function Label(
  translationKey: string,
  type: "solid" | "dimmed",
  arg?: TypographyType
) {
  return Typography(translationKey, {
    level: "title-sm",
    isLabel: true,
    ...(type === "solid" && { color: "primary.solid" }),
    ...(type === "dimmed" && { opacity: 0.5 }),
    ...(arg && arg),
  });
}

export function SolidLabelLine(translationKey: string, arg?: TypographyType) {
  return [Label(translationKey, "solid", { ...(arg && arg) })];
}
export function DimmedLabelLine(translationKey: string, arg?: TypographyType) {
  return [Label(translationKey, "dimmed", { ...(arg && arg) })];
}

export function LineWithSolidLabel(
  translationKey: string,
  line: CellType[],
  arg?: TypographyType
): {
  type: celltype;
  content: ContentType;
}[][] {
  return [SolidLabelLine(translationKey, arg), line];
}

export function LineWithDimmedLabel(
  translationKey: string,
  line: CellType[],
  arg?: TypographyType
): {
  type: celltype;
  content: ContentType;
}[][] {
  return [DimmedLabelLine(translationKey, arg), line];
}
