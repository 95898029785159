import { Box, CircularProgress, Stack, Typography } from "@mui/joy";
import useIsInappWebview from "hooks/useIsInappWebview";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import DefaultPage from "./DefaultPage";
import { useTranslation } from "react-i18next";
import AppleLoginButton from "components/user/AppleLoginButton";
import GoogleLoginButton from "components/user/GoogleLoginButton";
import useDetectIOS from "hooks/useDetectIOS";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import InstitutionStartButton from "components/user/InstitutionStartButton";
import character from "static/image/character/login_character.png";
import { isMobile } from "react-device-detect";

export default function Login() {
  let navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const permanantDataState = useRecoilValue(permanentDataAtom);
  const { isIOS } = useDetectIOS();
  const isInappWebview = useIsInappWebview();

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const isOnboarding = searchParams.get("type") === "1"; //신규모객

  useEffect(() => {
    if (!user || !user?.accessToken) {
      if (isOnboarding) {
        setMixPanel_new();
        navigate("/", { replace: true, state: { isOnboarding: true } });
      }
    } else {
      navigate("/", { ...state, replace: true });
    }
  }, [user]);

  useEffect(() => {
    setMixPanel_come();
  }, []);

  const setMixPanel_new = useMixpanelWeb({
    eventName: "0-0-A로 바로 진입",
  });

  const setMixPanel_come = useMixpanelWeb({
    eventName: "로그인 진입",
    params: {
      type: isMobile ? "app" : "web",
      ...(user?.appId && { appId: user?.appId }),
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const imageContainer = useRef<HTMLDivElement>(null);
  // const [imageContainerHeight, setImageContainerHeight] = useState<number>();

  // useEffect(() => {
  //   const observer = new ResizeObserver(() => {
  //     setImageContainerHeight(imageContainer.current?.offsetHeight);
  //   });

  //   if (imageContainer.current) {
  //     observer.observe(imageContainer.current);
  //   }

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  return (
    <DefaultPage
      content={
        !isLoading ? (
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"center"}
            spacing={"40px"}
            sx={{
              width: "100%",
              height: "100%",
              py: "24px",
            }}
          >
            <Stack spacing={"8px"} sx={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: "32px", fontWeight: "900" }}>
                {`스스로 이겨내는\n우울 불안 스트레스`}
              </Typography>
            </Stack>
            <Stack
              ref={imageContainer}
              alignItems="center"
              justifyContent={"center"}
              sx={{
                width: "100%",
                flex: 1,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={character}
                alt="empty"
                style={{
                  maxWidth: "300px",
                  // height: `${imageContainerHeight}px`,
                  // objectFit: "contain",
                  maxHeight: "100%", // 부모 높이를 넘지 않음
                  // maxWidth: "100%", // 부모 너비를 넘지 않음
                  objectFit: "contain", // 이미지 비율 유지하며 꽉 채움
                  position: "absolute", // 부모 기준으로 이미지 배치
                }}
              />
            </Stack>

            <Stack
              spacing={1}
              sx={{
                width: "100%",
                maxWidth: "500px",
              }}
              alignSelf="center"
            >
              <AppleLoginButton />
              <GoogleLoginButton />
              <InstitutionStartButton />
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems={"center"}>
            <CircularProgress color="neutral" />
          </Stack>
        )
      }
    />
  );
}
