import { useRecoilValue } from "recoil";
import { Box, Stack } from "@mui/joy";
import React, { useCallback, useEffect, useState } from "react";
import TestResult from "components/dashboard/TestResult";
import { patientAtom } from "recoil/patientAtom";
import {
  dashboardApi,
  DashboardType,
  DASHBOARD_DETAIL_ENDPOINT,
  DASHBOARD_DOMAIN,
} from "api/dashboardApi";
import { useQuery } from "@tanstack/react-query";
import ContentTemplate from "./ContentTemplate";
import BehavioralActivationProgress from "components/dashboard/BehaviorActivationProgress";

function Section({ content }: { content: React.ReactNode }) {
  return <Box sx={{ height: "100%" }}>{content}</Box>;
}

export default function MyProgressContentBehaviorActivation() {
  const patientState = useRecoilValue(patientAtom);

  const queryFn = useCallback(
    () => dashboardApi.load(`${patientState?.patientId}`),
    [patientState]
  );
  const [dashboardData, setDashboardData] = useState<DashboardType>();
  useQuery([DASHBOARD_DOMAIN, DASHBOARD_DETAIL_ENDPOINT], queryFn, {
    enabled: !!patientState?.patientId,
    onSuccess: (data) => {
      console.log(data.result);
      setDashboardData(data.result);
    },
  });

  useEffect(() => {
    if (!patientState?.patientId) {
      setDashboardData({
        concept: {},
        history: {},
        selfCheckResult: {},
      });
    }
  }, [patientState]);

  return (
    <ContentTemplate>
      {dashboardData && (
        <Stack spacing={2}>
          <BehavioralActivationProgress />

          <Section
            content={<TestResult {...dashboardData.selfCheckResult} />}
          />
        </Stack>
      )}
    </ContentTemplate>
  );
}
