import { Box, Stack, Typography } from "@mui/joy";
import logo_snu from "static/image/payment/logo_snu.png";
import logo_kcpa from "static/image/payment/logo_kcpa.png";
import logo_mohw from "static/image/payment/logo_mohw.png";
import logo_kcpa2 from "static/image/payment/logo_kcpa2.png";
import logo_kb from "static/image/payment/logo_kb.png";
import logo_tail from "static/image/payment/logo_tail.png";
import logo_trueinnovation from "static/image/payment/logo_trueinnovation.png";
import logo_springcamp from "static/image/payment/logo_springcamp.png";
import logo_weventures from "static/image/payment/logo_weventures.png";
import logo_shinhan from "static/image/payment/logo_shinhan.png";
import logo_superstart from "static/image/payment/logo_superstart.png";
import { motion } from "framer-motion";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

export default function Supporters() {
  const supporterList = [
    logo_snu,
    logo_kcpa,
    logo_mohw,
    logo_kcpa2,
    logo_kb,
    logo_tail,
    logo_trueinnovation,
    logo_springcamp,
    logo_weventures,
    logo_shinhan,
    logo_superstart,
  ];

  const repeatedList = Array(50).fill(supporterList).flat();
  // const backgroundColor = extractHexColor(theme.vars.palette.background.level1);
  const backgroundColor = "#ffffff";
  return (
    <Stack sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          zIndex: 10,
          left: 0,
          width: "20px",
          height: "100%",
          background: `linear-gradient(-90deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          zIndex: 10,
          right: 0,
          width: "20px",
          height: "100%",
          background: `linear-gradient(90deg, ${backgroundColor}00 0%, ${backgroundColor} 100%)`,
        }}
      />
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: -6000 }} // 원하는 만큼 왼쪽으로 이동 (e.g., -1000px)
        transition={{
          ease: "linear",
          duration: 100, // 10px/s 속도를 맞추기 위해 100초 동안 -1000px 이동
          repeat: Infinity, // 무한 반복
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          width: "100000%",
        }}
      >
        {repeatedList.map((image, index) => (
          <Stack sx={{}}>
            <img
              src={image}
              alt={"empty"}
              style={{
                width: "auto",
                height: "30px",
                paddingLeft: "20px",
              }}
            />
          </Stack>
        ))}
      </motion.div>
    </Stack>
  );
}
