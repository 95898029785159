import { Box, Button, Stack, Typography } from "@mui/joy";
import useOpenTask from "hooks/useOpenTask";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";

export default function PaymentModule({
  title,
  subtitle,
  type,
}: {
  title?: string;
  subtitle?: string;
  type?: "header" | "card";
}) {
  const openPayment = useOpenTask(`payment`);
  const user = useRecoilValue(userAtom);
  const subscription = user?.subscription;

  const { t: t_payment } = useTranslation("translation", {
    keyPrefix: "payment",
  });

  const text = {
    canceled: {
      title: t_payment(`n일 뒤 구독이 만료되어요`, {
        expiryDaysLeft: subscription?.expiryDaysLeft,
      }),
      subtitle: t_payment(`구독을 유지하고 프로그램을 이어가세요.`),
    },
    expired: {
      title: t_payment(`구독이 만료되었어요`),
      subtitle: t_payment(`다시 구독하고 프로그램을 이어가세요.`),
    },
    notstarted: {
      title: `나를 위한 맞춤 프로그램`,
      subtitle: `코치 선생님과 프로그램을 시작하세요.`,
    },
  };
  const { isCanceled, isExpired, isNotStarted } = useSubscriptionStatus();
  const isCard = type === "card";

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "primary.solid",
        ...(isCard && { borderRadius: "12px" }),
      }}
    >
      <Stack
        {...(isCard
          ? {
              direction: "column",
              justifyContent: "center",
            }
          : {
              direction: "row",
              justifyContent: "space-between",
            })}
        spacing="12px"
        alignItems="center"
        sx={{
          width: "100%",
          maxWidth: "720px",
          mx: "auto",
          px: "16px",
          py: "12px",
        }}
      >
        <Box sx={{ ...(isCard && { textAlign: "center" }) }}>
          <Typography
            sx={{ color: "white", fontSize: "16px", fontWeight: 700 }}
          >
            {title !== undefined
              ? title
              : isCanceled
              ? text["canceled"].title
              : isExpired
              ? text["expired"].title
              : isNotStarted
              ? text["notstarted"].title
              : text["notstarted"].title}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {subtitle !== undefined
              ? subtitle
              : isCanceled
              ? text["canceled"].subtitle
              : isExpired
              ? text["expired"].subtitle
              : isNotStarted
              ? text["notstarted"].subtitle
              : text["notstarted"].subtitle}
          </Typography>
        </Box>
        <Button
          color="white"
          size="sm"
          onClick={() => {
            openPayment();
          }}
          sx={{ ...(isCard && { width: "100%" }) }}
        >
          {t_payment("더 알아보기")}
        </Button>
      </Stack>
    </Stack>
  );
}
