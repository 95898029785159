import { Stack, Typography } from "@mui/joy";
import EditTaskListButton from "./EditTaskListButton";
import { useRecoilValue } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import NotificationButton from "./NotificationButton";
import { userAtom } from "recoil/userAtom";
import PatientItem from "components/patient-list/PatientItem";
import OpenChatButton from "./OpenChatButton";
import CoachWorkflow from "components/sidebar/workflow/CoachWorkflow";
import RiskDetection from "components/sidebar/RiskDetection";
import { isDev } from "config";
import { format, isToday, isTomorrow, isYesterday } from "date-fns";
import { ko } from "date-fns/locale";
import TrackToggleButton from "components/sidebar/workflow/TrackToggleButton";

export default function CoachMenu() {
  const userState = useRecoilValue(userAtom);
  const patientState = useRecoilValue(patientAtom);

  const isManual =
    userState?.role !== "patient" && userState?.isCoachAiSupportDisabled;

  const isAdmin = userState?.role === "admin";

  const showWorkflow =
    (patientState?.isInAutoProcessingStage
      ? patientState.isHumanCheckRequired
      : true) &&
    patientState?.isTaskUpdateRequired &&
    !patientState.isRiskCheckRequired &&
    !((patientState.unreadMessageCount || 0) > 0);

  const isFullAutoUpdate =
    patientState?.isInAutoProcessingStage &&
    patientState?.nextAutoTaskUpdateDateTime;

  function customFormatRelative(date: Date) {
    let prefix = "";

    if (isToday(date)) {
      prefix = "오늘";
    } else if (isTomorrow(date)) {
      prefix = "내일";
    } else if (isYesterday(date)) {
      prefix = "어제";
    }

    const timeFormat = "a h시 m분"; // 오전/오후 h시 m분 형식
    const formattedTime = format(date, timeFormat, { locale: ko });

    return prefix
      ? `${prefix} ${formattedTime}`
      : format(date, "PPpp", { locale: ko });
  }

  return (
    <Stack
      spacing={1}
      sx={{
        pt: "14px",
        pb: "20px",
      }}
    >
      <div className="pointer-events-none">
        {patientState && <PatientItem patient={patientState} />}
      </div>
      <Stack spacing={1}>
        {patientState?.isRiskCheckRequired && <RiskDetection />}
        {!isManual && showWorkflow && <CoachWorkflow />}
        {/* {isDev&&<TrackToggleButton />} */}
        {isFullAutoUpdate && (
          <Typography fontWeight={500}>
            {patientState.isHumanCheckRequired
              ? "예정 시각에 업데이트 실패"
              : `${customFormatRelative(
                  new Date(patientState.nextAutoTaskUpdateDateTime as string)
                )}에 업데이트 예정`}
          </Typography>
        )}
        {(isManual || isAdmin || isDev) && <EditTaskListButton />}
        {patientState?.isChatAvailable ? (
          <OpenChatButton />
        ) : (
          <NotificationButton />
        )}
      </Stack>
    </Stack>
  );
}
