import { useParams } from "react-router-dom";
import { Sheet, Stack } from "@mui/joy";
import Task from "components/task/Task";
import Sidebar from "components/sidebar/SideBar2";

export default function TaskPage() {
  const { taskKey } = useParams();
  const decodedTaskKey = decodeURIComponent(taskKey || "");

  return (
    <Sheet
      sx={{
        backgroundColor: "background.level2",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flexGrow: 0,
        whiteSpace: "pre-line",
      }}
      className="w-full h-full"
    >
      <Sidebar />
      <Stack
        sx={{
          flex: 1,
          backgroundColor: "background.level1",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          minWidth: 0,
        }}
      >
        {taskKey && <Task key={`task_${taskKey}`} taskKey={taskKey} />}
      </Stack>
    </Sheet>
  );
}
