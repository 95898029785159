import { useEffect, useState } from "react";
import { Box, Divider, List, Stack, Typography } from "@mui/joy";
import dayjs from "dayjs";
import * as _ from "lodash";
import { useRecoilValue } from "recoil";
import { userAtom } from "recoil/userAtom";
import { layoutAtom } from "recoil/layoutAtom";
import RecordListItem from "./RecordListItem";
import AddNewRecordButton from "./AddNewRecordButton";
import { MeditationRecordType, MeditationType } from "api/meditationRecordApi";
import OpenStatisticsButton from "./OpenStatisticsButton";
import useGetMeditationRecordVersion from "hooks/useGetMeditationRecordVersion";
import RecordListTabs from "../thoughtRecord/RecordListTabs";
import { removeDuplicate } from "logic/logics";
import useIsInappWebview from "hooks/useIsInappWebview";

export default function RecordList({
  recordList,
  currentRecord,
  setCurrentRecord,
  isStatisticsOpen,
  openStatistics,
}: {
  recordList?: MeditationRecordType[];
  currentRecord?: MeditationRecordType;
  setCurrentRecord: React.Dispatch<
    React.SetStateAction<MeditationRecordType | undefined>
  >;
  isStatisticsOpen: boolean;
  openStatistics: () => void;
}) {
  const user = useRecoilValue(userAtom);
  const isCoach = user?.role !== "patient";
  // const { isTaskContainerSmall } = useRecoilValue(layoutAtom);
  const isInappWebview = useIsInappWebview();
  const isTaskContainerSmall = isInappWebview;
  const [listData, setListData] = useState<MeditationRecordType[] | undefined>(
    recordList
  );

  const { isPracticeAvailable, isRegulationAvailable } =
    useGetMeditationRecordVersion();

  const [existTabList, setExistTabList] = useState<MeditationType[]>([
    "practice",
    "regulation",
  ]);
  const [selectedTab, setSelectedTab] = useState<MeditationType>(
    isPracticeAvailable
      ? "practice"
      : isRegulationAvailable
      ? "regulation"
      : "practice"
  );

  useEffect(() => {
    if (currentRecord?.type) {
      setSelectedTab(currentRecord.type);
    }
  }, [currentRecord]);

  const [currentTabListData, setCurrentTabListData] = useState<
    MeditationRecordType[] | undefined
  >(recordList?.filter((element) => element.type === selectedTab));

  useEffect(() => {
    setListData(recordList);
    const existTabList = removeDuplicate(recordList?.map((each) => each.type));
    // setExistTabList(existTabList as MeditationType[]);
    if (existTabList.length === 1) {
      setSelectedTab(existTabList[0] as MeditationType);
    }
  }, [recordList]);

  useEffect(() => {
    setCurrentTabListData(
      listData?.filter(
        (element) => !element.type || element.type === selectedTab
      )
    );
  }, [selectedTab, listData]);

  return (
    <Stack
      alignItems="center"
      sx={{
        flexShrink: 0,
        width: isTaskContainerSmall ? "100%" : "300px",
        height: isTaskContainerSmall ? "fit-content" : "100%",
        // overflow: "scroll",
        transition: "transform 0.4s, width 0.4s",
        scrollPaddingBlockStart: "20px",
        ...(isTaskContainerSmall ? { pb: "20px" } : { pl: "20px" }),
      }}
    >
      <Stack
        direction={isTaskContainerSmall ? "row" : "column"}
        justifyContent={"center"}
        spacing={isTaskContainerSmall ? "8px" : "16px"}
        divider={
          <Divider
            orientation={isTaskContainerSmall ? "vertical" : "horizontal"}
          />
        }
        sx={{
          width: "100%",
          // backgroundColor: "background.level1",

          ...(isTaskContainerSmall
            ? {
                px: "20px",
                height: "36px",
                mb: "16px",
              }
            : {
                flexShrink: 0,
                zIndex: 10,
                position: "sticky",
                pt: "20px",
                top: 0,
                pb: "16px",
              }),
        }}
      >
        <Stack
          sx={{ flexShrink: 0, width: isTaskContainerSmall ? "50px" : "100%" }}
        >
          <AddNewRecordButton
            disabled={
              isCoach ||
              ((listData || []).length === 1 &&
                !listData?.every((element) => element.lastDoneAt))
            }
          />
        </Stack>
        {existTabList && existTabList.length > 1 && (
          <RecordListTabs
            selectedTab={selectedTab}
            data={[
              {
                id: "practice",
                label: "연습하기",
                onClick: () => {
                  setSelectedTab("practice");
                },
              },
              {
                id: "regulation",
                label: "다스리기",
                onClick: () => {
                  setSelectedTab("regulation");
                },
              },
            ]}
          />
        )}
      </Stack>

      <List
        orientation={isTaskContainerSmall ? "horizontal" : "vertical"}
        sx={{
          overflow: "scroll",
          width: "100%",
          "--ListItem-paddingY": 0,
          "--ListItem-paddingX": 0,
          "--ListDivider-gap": 0,
        }}
      >
        {isPracticeAvailable && selectedTab === "practice" && (
          <Box
            sx={{ ...(isTaskContainerSmall ? { mx: "12px" } : { mb: "16px" }) }}
          >
            <OpenStatisticsButton
              isOpen={isStatisticsOpen}
              open={openStatistics}
            />
          </Box>
        )}

        {currentTabListData && currentTabListData.length > 0 ? (
          currentTabListData.map((record, index) => {
            return (
              <RecordListItem
                key={record.meditationRecordKey}
                width={isTaskContainerSmall ? "50px" : "100%"}
                direction={isTaskContainerSmall ? "row" : "column"}
                isFirstRecordOfMonth={
                  index === 0 ||
                  (index - 1 >= 0 &&
                    dayjs(currentTabListData[index - 1].date).get("month") !==
                      dayjs(currentTabListData[index].date).get("month"))
                }
                record={record}
                isSelected={
                  currentRecord?.meditationRecordKey ===
                  record.meditationRecordKey
                }
                setCurrentRecord={setCurrentRecord}
                shouldBeDone={!isCoach && record.shouldBeDone}
                durationMinutes={record.durationMinutes}
              />
            );
          })
        ) : (
          <Stack alignSelf={"center"} sx={{ width: "100%" }}>
            <Typography
              sx={{
                width: "100%",
                fontSize: "14px",
                opacity: 0.6,
                textAlign: "center",
              }}
            >
              {selectedTab === "practice"
                ? isPracticeAvailable
                  ? "진행한 훈련이 없어요."
                  : "아직 배우지 않았어요. 다스리기 훈련에 집중해주세요."
                : isRegulationAvailable
                ? "진행한 훈련이 없어요."
                : "아직 배우지 않았어요. 연습하기 훈련에 집중해주세요."}
            </Typography>
          </Stack>
        )}
      </List>
    </Stack>
  );
}
