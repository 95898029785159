import { Box, Typography, List, ListItem, Menu, Button } from "@mui/joy";
import { useResetRecoilState } from "recoil";
import { patientAtom } from "recoil/patientAtom";
import { userAtom, UserType } from "recoil/userAtom";
import UserProfile from "./UserProfile";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { sendMessage } from "utils/webview";
import ButtonWithModal from "components/common/ButtonWithModal";
import { useEffect, useState } from "react";
import useIsInappWebview from "hooks/useIsInappWebview";
import { programDataAtom } from "recoil/programDataAtom";
import { taskSectionAtom } from "recoil/taskSectionAtom";
import { useTranslation } from "react-i18next";
import { taskListAtom } from "recoil/taskListAtom";
import SteppaySubscriptionManager from "pages/SteppaySubscriptionManager";
import { isDev } from "config";
import { PATH } from "routes/paths";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import useLinkData from "hooks/useLinkData";

export default function UserDropDown({ user }: { user: UserType }) {
  const isInappWebview = useIsInappWebview();
  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userAtom);
  const resetPid = useResetRecoilState(patientAtom);
  const resetProgramData = useResetRecoilState(programDataAtom);
  const resetTaskSection = useResetRecoilState(taskSectionAtom);
  const resetTaskState = useResetRecoilState(taskListAtom);

  const { isSubscribingWithoutCancel } = useSubscriptionStatus();

  const { subscriptionCancelLink } = useLinkData();

  const goCancelSubscription = () => {
    if (isInappWebview) {
      sendMessage(
        JSON.stringify({
          event: "open_link",
          params: {
            url: subscriptionCancelLink,
          },
        })
      );
    } else {
      window.open(subscriptionCancelLink);
    }
  };

  const { t: t_ui } = useTranslation("translation", {
    keyPrefix: "ui",
  });

  const [showMenu, setShowMenu] = useState(false);

  const initializeUser = () => {
    resetUser();
    resetPid();
    resetProgramData();
    resetTaskSection();
    resetTaskState();
    navigate("/login");
    sendMessage(
      JSON.stringify({
        event: "logout",
      })
    );
    mixpanel.reset();
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      setShowMenu(false);
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setShowMenu((showMenu) => !showMenu);
        }}
      >
        <UserProfile
          nickname={user?.nickname}
          url={user?.profileImageUrl}
          size="sm"
          // color="primary"
        />
      </Box>

      {showMenu && (
        <Box
          className="w-fit absolute z-[10] left-[10px] bottom-[110%]"
          sx={{ backgroundColor: "background.level2" }}
        >
          <List
            sx={{
              whiteSpace: "nowrap",
              wordBreak: "keep-all",
              borderWidth: 1,
              borderColor: "background.level3",
              backgroundColor: "background.level2",
              "--List-gap": 0,
              "--ListItem-paddingY": 0,
            }}
          >
            {user.role === "patient" && (
              <Typography
                level={"body-xs"}
                fontWeight={600}
                sx={{ opacity: 0.5, px: "12px" }}
              >
                {user.userId}
              </Typography>
            )}
            {user.role === "patient" &&
              user.isSubscriptionManagementEnabled && (
                <ListItem>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (isInappWebview) {
                        sendMessage(
                          JSON.stringify({
                            event: "open_modal",
                            params: {
                              url: `${window.location.origin}/payment/management`,
                            },
                          })
                        );
                      } else {
                        navigate(PATH.steppayManagement);
                      }
                    }}
                  >
                    {t_ui("구독 관리")}
                  </Typography>
                </ListItem>
              )}
            <ListItem>
              <ButtonWithModal
                buttonText={t_ui("로그아웃")}
                fetchActionUrl={"/user/logout"}
                title={t_ui("정말로 로그아웃할까요?")}
                actionText={t_ui("로그아웃 하기")}
                isListItemButton
                actionButtonColor={"danger"}
                actionAfterFetch={initializeUser}
              />
            </ListItem>

            <ListItem>
              <ButtonWithModal
                buttonText={t_ui("회원탈퇴")}
                title={
                  isSubscribingWithoutCancel
                    ? t_ui("탈퇴하기 전에 이용 중인 구독을 먼저 취소해 주세요")
                    : t_ui("정말로 탈퇴할까요?")
                }
                actionText={t_ui(
                  isSubscribingWithoutCancel
                    ? t_ui("구독 취소하기")
                    : t_ui("탈퇴하기")
                )}
                isListItemButton
                actionButtonColor={"danger"}
                {...(isSubscribingWithoutCancel
                  ? { action: goCancelSubscription }
                  : {
                      fetchActionUrl: "/user/leave",
                      actionAfterFetch: initializeUser,
                    })}
              />
            </ListItem>
          </List>
        </Box>
      )}
      <Menu></Menu>
    </Box>
  );
}
