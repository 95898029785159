import { Box, Stack, Typography, useColorScheme } from "@mui/joy";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";

export default function CompareWithCounseling({
  type,
}: {
  type: "quantity" | "cost";
}) {
  const isQuantity = type === "quantity";
  function Container({
    text,
    price,
    isHighlight,
  }: {
    text: string;
    price: string;
    isHighlight: boolean;
  }) {
    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{
          width: "100%",
          py: "20px",
          px: "20px",
          ...(!isHighlight
            ? { backgroundColor: "background.level1" }
            : { borderWidth: "2px", borderColor: "primary.solid" }),
          borderRadius: "12px",
        }}
      >
        <Typography
          level="body-md"
          sx={{
            fontWeight: 700,

            ...(!isHighlight && {
              opacity: 0.5,
              textDecoration: "line-through",
            }),
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            ...(!isHighlight && {
              opacity: 0.5,
              textDecoration: "line-through",
            }),
          }}
        >
          {price}
        </Typography>
      </Stack>
    );
  }
  const data = {
    quantity: [
      { title: "심리상담", amount: "200분", width: "33%" },
      {
        title: "디스턴싱",
        amount: "600분",
        width: "100%",
        isHighlight: true,
        label: "3x",
      },
    ],
    cost: [
      { title: "심리상담", amount: "120만원", width: "100%" },
      {
        title: "디스턴싱",
        amount: "24만원",
        width: "20%",
        isHighlight: true,
        label: "1/5x",
      },
    ],
  };
  return (
    <Stack sx={{ width: "100%" }} spacing={"10px"} alignItems="center">
      {/* {[
        {
          text: isQuantity ? "심리상담 1개월" : "심리상담 3개월",
          price: isQuantity ? "약 200분" : "약 120만원",
          isHighlight: false,
        },
        {
          text: isQuantity ? "디스턴싱 1개월" : "디스턴싱 3개월",
          price: isQuantity ? "600분" : "24만원",
          isHighlight: true,
        },
      ].map(({ text, price, isHighlight }) => (
        <Container text={text} price={price} isHighlight={isHighlight} />
      ))} */}
      {data[type].map(({ title, amount, width, isHighlight, label }) => (
        <Stack spacing="4px" sx={{ width: "100%" }}>
          <Box
            sx={{
              width: width,
              height: "10px",
              borderRadius: "10px",
              ...(isHighlight
                ? { backgroundColor: "primary.solid" }
                : { backgroundColor: "common.black", opacity: 0.4 }),
            }}
          ></Box>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Typography
              level="body-sm"
              fontWeight={600}
              sx={{
                ...(isHighlight
                  ? { color: "primary.solid" }
                  : { color: "common.black", opacity: 0.6 }),
              }}
            >
              {title}
            </Typography>
            {label && (
              <Typography
                level="title-md"
                fontWeight={700}
                sx={{ color: "primary.solid" }}
              >
                {label}
              </Typography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
