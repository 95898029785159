import { memo } from "react";
import { getTaskIdFromTaskKey } from "logic/logics";
import useGetLocale from "hooks/useGetLocale";

function ImageCell({ taskKey, url }: { taskKey: string; url: string }) {
  const locale = useGetLocale();
  return (
    <img
      style={{ borderRadius: "6px" }}
      alt={"empty"}
      src={
        url.includes(".png")
          ? taskKey.startsWith("4-5")
            ? require(`../../../../static/activity/${`4-5-A`}/${url}`)
            : taskKey.includes("_")
            ? require(`../../../../static/activity/${
                taskKey.split("_")[0]
              }/${url}`)
            : require(`../../../../static/activity/${getTaskIdFromTaskKey(
                `${taskKey}`
              )}/${url}`)
          : taskKey.startsWith("4-5")
          ? require(`../../../../static/activity/${`4-5-A`}/${url}_${locale}.png`)
          : taskKey.includes("_")
          ? require(`../../../../static/activity/${
              taskKey.split("_")[0]
            }/${url}_${locale}.png`)
          : require(`../../../../static/activity/${getTaskIdFromTaskKey(
              `${taskKey}`
            )}/${url}_${locale}.png`)
      }
    />
  );
}

export default memo(ImageCell);
