import { isDev } from "config";
import { useEffect, useState } from "react";
import { appLog } from "utils/webview";
import useGetDeviceInfo from "./useGetDeviceInfo";
import useIsInappWebview from "./useIsInappWebview";

export default function useIsLatest() {
  const isInappWebview = useIsInappWebview();
  const { appVersion } = useGetDeviceInfo();

  // 버전 비교 함수
  const compareVersions = (versionA: string, versionB: string) => {
    const a = versionA.split(".").map(Number);
    const b = versionB.split(".").map(Number);

    for (let i = 0; i < Math.max(a.length, b.length); i++) {
      if (a[i] === undefined || a[i] < b[i]) {
        return -1;
      } else if (a[i] > b[i]) {
        return 1;
      }
    }

    return 0;
  };
  // useEffect(() => {
  //   appLog(
  //     `${appVersion} ${compareVersions(appVersion, requiredVersion) >= 0}`
  //   );
  // }, [appVersion]);
  const requiredVersion = "1.0.29";
  const [isLatest] = useState(
    !isInappWebview || compareVersions(appVersion, requiredVersion) >= 0
  );
  return isLatest;
}
