import { useNavigate, useParams } from "react-router-dom";
import Acquisition from "./Acquisition";
import onboarding_program from "../../static/image/onboarding/onboarding_program.png";
import onboarding_coach from "../../static/image/onboarding/onboarding_coach.png";
import onboarding_protection from "../../static/image/onboarding/onboarding_protection.png";
import FullPageWithTitleAndButton from "pages/FullPageWithTitleAndButton";
import useMixpanelWeb from "hooks/useMixpanelWeb";
import { useEffect } from "react";

export default function OnboardingBeforeLogin() {
  let navigate = useNavigate();

  const { id } = useParams();
  const numId = parseInt(id || "1");

  const dataList = [
    {
      title: "스스로 이겨내는 우울 불안 스트레스",
      subtitle: "디스턴싱은 배우고, 연습하고, 실천하는 프로그램이에요.",
      image: onboarding_program,
    },
    {
      title: "매일 함께하는 전담 코치",
      subtitle:
        "1:1 전담 코치는 내 상태를 분석해 보고, 나에게 맞는 활동을 매일 준비해 줄 거랍니다.",
      image: onboarding_coach,
    },
    {
      title: "안전하게 보호되는 내 정보",
      subtitle: "프로그램은 익명으로 진행되니 안심하고 이용할 수 있어요.",
      image: onboarding_protection,
    },
  ];
  const goNext = () => {
    navigate(
      numId === dataList.length ? "/login" : `/onboarding/guest/${numId + 1}`
    );
  };
  const setMixPanel = useMixpanelWeb({
    eventName: "온보딩",
    params: {
      pageId: `${id}`,
    },
  });

  useEffect(() => {
    setMixPanel();
  }, [id]);

  return (
    <FullPageWithTitleAndButton
      title={dataList[numId - 1].title}
      subtitle={dataList[numId - 1].subtitle}
      image={dataList[numId - 1].image}
      buttonText={"다음"}
      buttonDisabled={false}
      progress={(numId / dataList.length) * 100}
      buttonClickAction={goNext}
    ></FullPageWithTitleAndButton>
  );
}
